import React from 'react'
import { NavigationContainer } from '@react-navigation/native'

import RootStack from './stacks/RootStack'
import linking from './linking'

export default function Navigation() {
	return (
		<NavigationContainer linking={linking}>
			<RootStack />
		</NavigationContainer>
	)
}
