import React from 'react'
import { StyleSheet, View, Dimensions, Animated, Image, Platform, TouchableOpacity } from 'react-native'
import { StyledButton, Tex, LTex, WhiteText } from '@utils/styled-components'
import { connect } from 'react-redux'
import styled from 'styled-components/native'
import { Ionicons } from '@expo/vector-icons'
import { MdReplyAll } from 'react-icons/md'

import withRouter from '@utils/withRouter'

const { height, width } = Dimensions.get('window')
const IMAGEHEIGHT = height * 0.5

const TitleSection = styled.View`
	padding: 18px 20px;
	position: relative;
	width: 100%;
	justify-content: flex-start;
	text-align: center;
`
const MenuName = styled(LTex)`
	color: #3d3e43;
	font-size: 20px;
	font-weight: 700;
	line-height: 35px;
`
const MenuDesc = styled(LTex)`
	color: #646564;
	font-size: 16px;
	font-weight: 300;
	line-height: 26px;
`
const BottomSection = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 20px 70px 20px;
	background-color: #fff;
`
const GreyInterstice = styled.View`
	height: 10px;
	width 100%;
	background-color: #EFEFEF;	
`
const NumberCircle = styled.TouchableOpacity`
	height: 48px;
	width: 48px;
	border-width: 1px;
	border-color: #646564;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 10px;
`
const TopRow = styled.View`
	position: absolute;
	top: 15px;
	display: flex;
	justify-content: space-between;
	padding: 20px;
	width: 100%;
	z-index: 2;
	flex-direction: row;
`
const AddToCartView = styled.View`
	width: 100%;
	padding: 0 25px 20px 25px;
	justify-content: center;
	align-items: center;
	background-color: #fff;
`
const AllergyWarning = styled(LTex)`
	color: #b4b5b8;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	margin: 15px 0;
`

class Product extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			quantity: 1,
			totalPrice: 0,
			isModalVisible: false,
		}
	}

	static navigationOptions = {
		headerShown: false,
	}

	addToCart() {
		if (this.props.basketStore.restaurantId && this.props.product.restaurantId != this.props.basketStore.restaurantId) {
			this.setState({
				isModalVisible: true,
			})
		} else {
			this.addItemToCart()
			this.props.backToItems()
		}
	}

	addItemToCart() {
		let item = {}
		item.quantity = this.state.quantity
		item.price = this.state.totalPrice
		item.productId = this.props.product.productId
		item.name = this.props.product.name
		item.categoryName = this.props.product.categoryName
		item.image = this.props.product.image
		item.type = 'product'
		let action = {
			type: 'ADD_TO_BASKET',
			values: {
				// restaurantName: this.props.restaurantName,
				restaurantId: this.props.product.restaurantId,
				item,
			},
		}
		this.props.dispatch(action)
	}

	componentDidMount() {
		this.setState({
			totalPrice: parseFloat(this.props.product.price).toFixed(2),
		})
	}

	render() {
		const colors = this.props.appStore.restaurant.style
		return (
			<View style={styles.container}>
				<TopRow>
					<TouchableOpacity
						style={styles.icon}
						onPress={() => {
							this.props.backToItems()
						}}
					>
						<MdReplyAll color={colors.secondary} size={24} />
					</TouchableOpacity>
				</TopRow>
				<Animated.ScrollView style={{ position: 'relative' }} showsVerticalScrollIndicator={false}>
					<View style={styles.imageContainer}>
						<Animated.Image
							source={{
								uri: 'http://res.cloudinary.com/drive-eat/image/fetch/h_1000/' + this.props.product.image,
							}}
							style={{ width: '100%', height: IMAGEHEIGHT, zIndex: 1 }}
							resizeMode='cover'
						/>
					</View>
					<View style={styles.productContainer}>
						<TitleSection>
							<MenuName style={{ color: colors.primary }}>{this.props.product.name}</MenuName>
							<MenuDesc>{this.props.product.description}</MenuDesc>
						</TitleSection>
					</View>
					<GreyInterstice />
					<BottomSection>
						<MenuName style={{ color: colors.primary }}>Quantité</MenuName>
						<View style={styles.quantity}>
							<NumberCircle
								onPress={() =>
									this.setState({
										quantity: Math.max(1, this.state.quantity - 1),
									})
								}
							>
								<Tex>-</Tex>
							</NumberCircle>
							<Tex fontSize='22px'>{this.state.quantity}</Tex>
							<NumberCircle
								onPress={() =>
									this.setState({
										quantity: this.state.quantity + 1,
									})
								}
							>
								<Tex>+</Tex>
							</NumberCircle>
						</View>
						<AllergyWarning>
							Si vous souffrez d’allergies alimentaires veuillez en aviser directement le restaurant avant de passer commande
						</AllergyWarning>
					</BottomSection>
				</Animated.ScrollView>
				<View style={styles.wrapper}>
					<AddToCartView>
						<StyledButton
							colors={colors}
							flexDirection='row'
							justifyContent='space-around'
							onPress={() => {
								this.addToCart()
							}}
						>
							<Tex color='rgba(42,42,42,0.0)'>XXX</Tex>
							<WhiteText bold>Ajouter au panier</WhiteText>
							<WhiteText bold>{parseFloat(this.state.totalPrice).toFixed(2)} €</WhiteText>
						</StyledButton>
					</AddToCartView>
				</View>
			</View>
		)
	}
}

const styles = StyleSheet.create({
	container: { flex: 1, backgroundColor: '#EBEBEB' },
	icon: {
		backgroundColor: '#FFF',
		borderRadius: 5,
		height: 33,
		width: 33,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	productContainer: {
		borderTopLeftRadius: 30,
		borderTopRightRadius: 30,
		backgroundColor: '#FFF',
		marginTop: -25,
	},
	shadow: {
		shadowColor: '#FFFFFF',
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.12,
		shadowRadius: 1.22,

		elevation: 3,
		overflow: 'hidden',
	},
	wrapper: {
		width: '100%',
		backgroundColor: 'rgba(0,0,0,0.0)',
		bottom: 0,
		paddingVertical: 0,
		display: 'flex',
		flexDirection: 'row',
		height: 60,
		zIndex: 1000,
		position: 'absolute',
	},
	imageContainer: {
		position: 'relative',
		marginTop: -1000,
		paddingTop: 1000,
		overflow: 'hidden',
	},
	quantity: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 20,
		marginTop: 10,
	},
})

const mapStateToProps = (state) => {
	return state
}
export default connect(mapStateToProps)(withRouter(Product))

const Url =
	'https://vegnews.com/media/W1siZiIsIjE2NDEwL1ZlZ05ld3MuTmVzdGxlVmVnYW5CYWNvbkNoZWVzZWJ1cmdlci5qcGciXSxbInAiLCJjb252ZXJ0IiwiLWNyb3AgMjU5OHgxNTM2KzArMTcxICtyZXBhZ2UgLXJlc2l6ZSA4MDB4NDczXiIseyJmb3JtYXQiOiJqcGcifV0sWyJwIiwib3B0aW1pemUiXV0/VegNews.NestleVeganBaconCheeseburger.jpg?sha=41d957bf2883d273'
