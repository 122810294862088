import React from "react";
import { StyleSheet, View, Image } from "react-native";
import { connect } from "react-redux";
import styled from "styled-components/native";
import { LTex, Tex } from "@utils/styled-components";
import withRouter from "@utils/withRouter";

const Card = styled.TouchableOpacity`
	width: 100%;
	display: flex;
	padding: 10px;
	border-radius: 10px;
	margin-vertical: 5px;
	background-color: #ffffff;
	elevation: 3;
`;
const Row = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;
const ItemName = styled(LTex)`
	color: #40403f;
	font-size: 16px;
	font-weight: 700;
	line-height: 16px;
`;
const Item = styled(LTex)`
	color: #646564;
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
`;
const Supplement = styled(LTex)`
	color: #A6A5A6;	
	font-size: 14px;	
	font-weight: 500;	
	line-height: 20px;}
`;
const NumberCircle = styled.TouchableOpacity`
	height: 30px;
	width: 30px;
	border-width: 1px;
	border-radius: 10px;
	border-color: #ddd;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
`;

class BasketOrderItem extends React.Component {
	// receives an 'item' as props
	constructor(props) {
		super(props);
		this.state = {};
	}

	getItemPrice = (item) => {
		let itemPrice = parseFloat(item.price);
		return itemPrice * item.quantity;
	};

	removeQuantity = () => {
		let quantity = Math.max(0, this.props.item.quantity - 1);
		let action = {
			type: "CHANGE_QUANTITY",
			values: {
				timestamp: this.props.item.timestamp,
				quantity,
			},
		};
		this.props.dispatch(action);
	};

	addQuantity = () => {
		let quantity = this.props.item.quantity + 1;
		let action = {
			type: "CHANGE_QUANTITY",
			values: {
				timestamp: this.props.item.timestamp,
				quantity,
			},
		};
		this.props.dispatch(action);
	};

	render() {
		let item = this.props.item;
		return (
			<Card
				style={styles.shadow}
				onPress={() => {
					this.props.onPress(item);
				}}
				disabled={this.props.disabled}
			>
				<Row>
					<View style={{ flex: 2, flexDirection: "row", alignItems: "center" }}>
						{item.image ? (
							<Image
								source={{
									uri:
										"http://res.cloudinary.com/drive-eat/image/fetch/w_100/" +
										item.image,
								}}
								style={styles.img}
							/>
						) : null}
						<ItemName numberOfLines={1}>
							<LTex color='#ed1c24'>{item.quantity}x </LTex>
							{item.name}
						</ItemName>
					</View>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<NumberCircle
							onPress={() => {
								this.removeQuantity();
							}}
						>
							<Tex bold>-</Tex>
						</NumberCircle>
						<Tex bold>{item.quantity}</Tex>
						<NumberCircle
							onPress={() => {
								this.addQuantity();
							}}
						>
							<Tex bold>+</Tex>
						</NumberCircle>
					</View>
					<View
						style={{
							flex: 0.7,
							alignItems: "flex-end",
						}}
					>
						<LTex bold> {this.getItemPrice(item).toFixed(2)} €</LTex>
					</View>
				</Row>
				<View style={styles.bitOfPadding}>
					{item.selectedItems &&
						item.selectedItems.length > 0 &&
						item.selectedItems.map((mpi, index) => {
							return mpi.supplement || mpi.supplementAfterMax ? (
								<View key={`mpi-${index}`} style={{ flexDirection: "row" }}>
									<Supplement>
										+ {mpi.quantity > 1 ? mpi.quantity : ""} {mpi.name}{" "}
									</Supplement>
									<Supplement>
										(
										{(
											parseFloat(mpi.price) *
											(mpi.supplementAfterMax
												? mpi.quantitySupplement || 1
												: mpi.quantity)
										).toFixed(2)}{" "}
										€)
									</Supplement>
								</View>
							) : (
								<Item key={`mpi-${index}`}>
									{mpi.quantity > 1 ? mpi.quantity : ""} {mpi.name}
								</Item>
							);
						})}
				</View>
			</Card>
		);
	}
}

const styles = StyleSheet.create({
	img: {
		width: 30,
		height: 30,
		marginRight: 5,
		resizeMode: "contain",
	},
	shadow: {
		shadowColor: "#FFF",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.34,
		shadowRadius: 3.84,

		elevation: 1,
		// overflow: 'hidden',
	},
	bitOfPadding: { paddingLeft: 25 },
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(BasketOrderItem));
