import React from "react";
import { Text, StyleSheet, View, button, Dimensions } from "react-native";
import styled from "styled-components/native";
import { useTimer } from "react-timer-hook";
import { MdOutlineAccessTime } from "react-icons/md";
import dayjs from "dayjs";

const MyText = styled.Text`
	color: #3d3e43;
	font-size: 16px;
	font-weight: ${(props) => (props.bold ? "bold" : "300")};
	line-height: 26px;
	padding-left: 10px;
	width: 100%;
`;
// export default function MyTimer({expiryTimestamp, props }) {
export default function MyTimer({ timer, onExpire }) {
	const {
		seconds,
		minutes,
		hours,
		days,
		isRunning,
		start,
		pause,
		resume,
		restart,
	} = useTimer({
		expiryTimestamp: timer.toDate(),
		onExpire: () => onExpire(),
	});

	return (
		<View
		// style={{
		// 	width: '100%',
		// 	// paddingHorizontal: 2,
		// 	// borderWidth: 1,
		// 	// borderColor: '#DDD',
		// 	// borderRadius: 30,
		// 	alignItems: 'center',
		// }}
		>
			{/* <>
				<MdOutlineAccessTime style={{ marginRight: 20 }} color={'red'} size={28} />
				<MyText>Temps d'attente estimé :</MyText>
			</> */}

			<MyText bold>
				{(minutes + "").padStart(2, "0")} : {(seconds + "").padStart(2, "0")}
				min
			</MyText>
		</View>
	);
}

// export default function App(props) {
// 	return (
// 		<View>
// 			<MyTimer expiryTimestamp={props.timer.toDate()} />
// 		</View>
// 	)
// }
