import React from "react";
import {
	StyleSheet,
	View,
	Text,
	TouchableOpacity,
	Dimensions,
	FlatList,
} from "react-native";
import { connect } from "react-redux";
import withRouter from "@utils/withRouter";

const MARGIN = 2;
const CARD_HEIGHT = 50;
const CARD_WIDTH = 120;
const ITEM_HEIGHT = CARD_HEIGHT + MARGIN * 2;
const ITEM_WIDTH = CARD_WIDTH + MARGIN * 2;
const { height: wHeight, width } = Dimensions.get("window");
const height = wHeight * 1;

const Item = (props) => {
	return (
		<TouchableOpacity
			style={[
				styles.item,
				{
					backgroundColor: props.selected ? props.colors.secondary : "#FFF",
				},
			]}
			onPress={() => props.onPress(props.screen)}
		>
			{/* <Image
					source={{
						uri: 'http://res.cloudinary.com/drive-eat/image/fetch/w_150/' + props.image,
					}}
					resizeMode='contain'
					style={{ width: '65%', height: '65%', zIndex: 1000 }}
				/> */}
			<Text
				style={[
					styles.title,
					{
						color: props.selected ? "#FFF" : props.colors.primary,
						fontWeight: props.selected ? "bold" : "500",
					},
				]}
			>
				{props.categoryTitle}
			</Text>
		</TouchableOpacity>
	);
};

const CategoriesBar = (props) => {
	const flatListRef = React.useRef();

	const colors = props.appStore.restaurant.style;
	console.log(colors);

	React.useEffect(() => {
		if (flatListRef.current && props.appStore.allCategories) {
			let index = props.appStore.allCategories.findIndex(
				(cat) => cat.categoryName == props.appStore.menuItemSelected
			);
			if (index === -1) {
				index = 1;
			}
			flatListRef.current.scrollToIndex({ index: index, viewPosition: 0 });
		}
	}, [props.appStore.menuItemSelected]);

	const renderItem = ({ item, index }) => {
		return (
			<Item
				colors={colors}
				screen={item.categoryName}
				categoryTitle={item.categoryName}
				image={item.categoryImage}
				onPress={() => {
					if (props.onPress) {
						props.onPress(item.categoryName);
					}
					console.log(item.categoryName, props.appStore.menuItemSelected);
					if (item.categoryName != props.appStore.menuItemSelected) {
						let action = {
							type: "CHANGE_MENU",
							itemSelected: item.categoryName,
							filter: props.navTo || 0,
						};
						props.dispatch(action);
					}
					//flatListRef.current.scrollToIndex({index: index, viewPosition: 0});

					if (props.navTo) {
						props.navigation.navigate("RestaurantScreen", {
							appId: this.props.appStore.app.appId,
						});
					}
				}}
				selected={props.selected == item.categoryName ? 1 : 0}
				key={item.categoryName}
				index={index}
			/>
		);
	};

	return (
		<View style={styles.wrapper}>
			<FlatList
				horizontal
				contentContainerStyle={{
					justifyContent: "center",
					alignItems: "flex-start",
				}}
				showsHorizontalScrollIndicator={false}
				snapToInterval={ITEM_WIDTH}
				data={props.categories}
				renderItem={renderItem}
				keyExtractor={(item) => item.categoryName}
				ref={flatListRef}
				onScrollToIndexFailed={() => {}}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	wrapper: {
		width: "100%",
		height: 64,
		// backgroundColor: 'rgb(250, 250, 250)',
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-around",
		zIndex: 1000,
		marginTop: 10,
	},
	item: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: CARD_HEIGHT,
		width: CARD_WIDTH,
		borderRadius: 10,
		paddingHorizontal: 3,
		marginHorizontal: MARGIN,
		elevation: 2,
	},
	title: {
		fontSize: 16,
		lineHeight: 18,
		fontWeight: "500",
		textAlign: "center",
	},
	shadow: {
		shadowColor: "#FFF",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.3,
		shadowRadius: 1,

		elevation: 1,
	},
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(CategoriesBar));
