import styled from 'styled-components/native'

const StyledButton = styled.TouchableOpacity`
	height: 55px;
	border-radius: ${(props) => (props.radius ? props.radius : '15px')};
	background-color: ${(props) => (props.bgColor ? props.bgColor : props.disabled ? '#EFEFEF' : props.colors.secondary)};
	display: flex;
	align-items: center;
	justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
	width: ${(props) => (props.width ? props.width : '90%')};
	color: white;
	flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
	margin: ${(props) => (props.margin ? props.margin : '0')};
`

const WhiteText = styled.Text`
	color: white;
	font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
	font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`

const Center = styled.View`
	flex: ${(props) => (props.flex ? props.flex : '1')};
	text-align: center;
	justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
	align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
	width: ${(props) => (props.width ? props.width : '100%')};
	margin: ${(props) => (props.marg ? props.marg : '0')};
	position: ${(props) => (props.position ? props.position : 'relative')};
	padding: ${(props) => (props.padding ? props.padding : '0')};
	flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
	background-color: ${(props) => (props.bgColor ? props.bgColor : 'white')};
	z-index: ${(props) => (props.zIndex ? props.zIndex : '1')};
	border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0')};
`

const Tex = styled.Text`
	color: ${(props) => (props.color ? props.color : '#3D3E43')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
	text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
	margin: ${(props) => (props.marg ? props.marg : '0')};
	padding: ${(props) => (props.padding ? props.padding : '0')};
	max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
	font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
	font-family: SFUIText-Medium;
	${(props) => (props.lineHeight ? 'line-height: ' + props.lineHeight : null)};
`

const LTex = styled.Text`
	color: ${(props) => (props.color ? props.color : '#3D3E43')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
	text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
	margin: ${(props) => (props.marg ? props.marg : '0')};
	padding: ${(props) => (props.padding ? props.padding : '0')};
	max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
	font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
	font-family: SFUIText-Medium;
	line-height: ${(props) => (props.lineHeight ? props.lineHeight : '17px')};
`
const Div = styled.View`
	background-color: ${(props) => (props.bgColor ? props.bgColor : 'white')};
	padding: ${(props) => (props.padding ? props.padding : '0')};
	margin: ${(props) => (props.marg ? props.marg : '0')};
	flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
	justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
	align-items: ${(props) => (props.alignItems ? props.alignItems : 'flex-start')};
	width: ${(props) => (props.width ? props.width : '100%')};
	flex: ${(props) => (props.flex ? props.flex : '1')};
	background-color: ${(props) => (props.bgColor ? props.bgColor : 'white')};
`
const TouchableDiv = styled.TouchableOpacity`
	background-color: ${(props) => (props.bgColor ? props.bgColor : 'white')};
	padding: ${(props) => (props.padding ? props.padding : '0')};
	margin: ${(props) => (props.marg ? props.marg : '0')};
	flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
	justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
	align-items: ${(props) => (props.alignItems ? props.alignItems : 'flex-start')};
	width: ${(props) => (props.width ? props.width : '100%')};
	flex: ${(props) => (props.flex ? props.flex : '1')};
`
const Title = styled(Tex)`
	font-size: 32px;
	color: #3d3e43;
	font-weight: bold;
	font-family: SFUIText-Medium;
`
const LTitle = styled(LTex)`
	font-size: 32px;
	color: #3d3e43;
	font-weight: bold;
`
const LSubTitle = styled(LTex)`
	margin-top: ${(props) => (props.marginTop ? props.marginTop : '15px')};
	margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '5px')};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '22px')};
	color: ${(props) => (props.color ? props.color : '#3d3e43')};
	font-weight: bold;
	margin: ${(props) => (props.margin ? props.margin : '0')};
`
const SubTitle = styled(Tex)`
	margin-top: ${(props) => (props.marginTop ? props.marginTop : '15px')};
	margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '5px')};
	font-size: 20px;
	color: #3d3e43;
	font-weight: bold;
`

module.exports = {
	StyledButton,
	Center,
	Tex,
	WhiteText,
	Title,
	LTex,
	Div,
	LTitle,
	LSubTitle,
	TouchableDiv,
	SubTitle,
}
