import React from "react";
import { StyleSheet, View, FlatList } from "react-native";
import { Center } from "@utils/styled-components";
import { connect } from "react-redux";
import { BASE_URL } from "@env";
import MenuItem from "@components/menuItem";
import Loader from "@components/Loader";
import { get } from "@utils/api";
import ShowBasketButton from "@components/showBasketButton";
import Banniere from "@components/Banniere";
import dayjs from "dayjs";
import CategoriesBar from "@components/CategoriesBar";
import Product from "@screens/product/product";

import withRouter from "@utils/withRouter";

class Restaurant extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			selectedCategory: "Test",
			categories: [],
			scrolling: false,
			filter: null,
			filterItems: [],
			allItems: [],
			currentNav: null,
			currentIndex: 0,
			showArrows: false,
		};
		this.rowz = {};
		this.flatListRef = null;
	}

	static navigationOptions = {
		headerShown: false,
	};

	getMenusProductsCategories(restaurantId) {
		get(
			"/getMenusProductsCategories/" + restaurantId,
			(res) => {
				let allItems = res.results.menusWithCategories.concat(
					res.results.productsWithCategories
				);
				let allItemsGrouped = allItems.reduce((r, a) => {
					r[a.categoryName] = [...(r[a.categoryName] || []), a];
					return r;
				}, {});

				this.setState({
					productsWithCategories: res.results.productsWithCategories,
					menusWithCategories: res.results.menusWithCategories,
					allItems: allItemsGrouped,
					isReady2: true,
					categories: res.results.categories,
					selectedCategory: res.results.categories[0],
					isReady: true,
					loading: false,
				});

				let action = {
					type: "SAVE_DATA",
					values: {
						productsWithCategories: res.results.productsWithCategories,
						menusWithCategories: res.results.menusWithCategories,
						allCategories: res.results.categories,
					},
				};
				this.props.dispatch(action);

				this.filter(res.results.categories[0].categoryName);
			},
			(err) => {
				console.warn(err.status);
			}
		);
	}

	filterItems = [];

	filterByCategory = (categoryName) => {
		return this.state.allItems[categoryName];
	};
	filter(itemSelected) {
		this.setState({ loading: true });
		let filterItems = this.filterByCategory(itemSelected);

		let _that = this;

		this.filterItems = filterItems;

		let currentNav = this.state.categories.find(
			(elt) => elt.categoryName == itemSelected
		);
		_that.setState({
			filterItems: filterItems,
			loading: false,
			currentNav,
			filter: itemSelected,
		});

		if (this.flatListRef) {
			this.flatListRef.scrollToIndex({ index: 0 });
		}
	}

	componentDidMount() {
		this.getMenusProductsCategories(
			this.props.appStore.restaurant.restaurantId
		);
	}

	componentDidUpdate(prevProps, prevState) {
		let newFilter = this.props.appStore.menuItemSelected;

		if (
			prevProps.appStore.menuItemSelected !== newFilter &&
			this.props.appStore.filter
		) {
			this.filter(newFilter);
		}
	}

	checkBasket() {
		if (this.props.basketStore && this.props.basketStore.updatedAt) {
			let now = dayjs();
			let updatedAt = dayjs(
				this.props.basketStore.updatedAt,
				"YYYY-MM-DD HH:mm:ss"
			);
			console.log("test panier");
			if (now.diff(updatedAt) > 60 * 60 * 1000) {
				console.log("panier expiré");
				let action = {
					type: "CLEAR_BASKET",
				};
				this.props.dispatch(action);
			}
		}
	}

	// goBack() {
	// 	const { navigation } = this.props;
	// 	navigation.goBack();
	// 	navigation.state.params.onRefresh();
	// }

	renderItem = ({ item, index }) => {
		const { navigate } = this.props.navigation;
		return (this.state.currentNav.menu && item.menuId) ||
			!this.state.currentNav.menu ? (
			<View key={item.productId || item.menuId} style={styles.listContainer}>
				<MenuItem
					onPress={() => {
						item.menuId != null
							? navigate("MenuScreen", {
									menuId: item.menuId,
									restaurantName: this.props.appStore.restaurant.name,
									uri:
										"https://res.cloudinary.com/drive-eat/image/fetch/w_350/" +
										BASE_URL +
										this.props.appStore.restaurant.frontPicture,
									appId: this.props.appStore.app.appId,
									title: item.name,
							  })
							: this.displayProduct(item);
					}}
					key={item.productId || item.menuId}
					refArr={this.rowz}
					categoryName={item.categoryName}
					index={index}
					name={item.title}
					price={parseFloat(item.price).toFixed(2)}
					desc={item.description}
					disabled={item.disabled}
					img={item.frontPicture && BASE_URL + item.frontPicture}
					image={item.image}
				/>
			</View>
		) : null;
	};

	displayProduct = (product) => {
		this.setState({ product });
	};

	backToItems = () => {
		this.setState({ product: null });
	};
	keyExtractor = (item) =>
		item.menuId ? "m" + item.menuId : "p" + item.productId;

	render() {
		const colors = this.props.appStore.restaurant.style;
		const { navigate } = this.props.navigation;
		this.checkBasket();
		if (!this.state.isReady || !this.state.isReady2) {
			return (
				<Center>
					<Loader name={"circle"} color={colors.secondary} />
				</Center>
			);
		}
		return (
			<View style={styles.container}>
				{!this.state.product && (
					<>
						<Banniere {...this.props.appStore} />
						<CategoriesBar
							categories={this.state.categories}
							selected={this.state.filter}
							onPress={(itemSelected) => {
								this.setState({ product: null });
								this.filter(itemSelected);
								if (this.flatListRef) {
									this.flatListRef.scrollToIndex({ index: 0, viewPosition: 0 });
								}
								this.setState({ currentIndex: 0 });
							}}
						/>
					</>
				)}

				{!this.state.loading ? (
					this.state.product ? (
						<Product
							product={this.state.product}
							backToItems={() => this.backToItems()}
						/>
					) : (
						<FlatList
							data={this.filterItems}
							renderItem={this.renderItem}
							extraData={this.state}
							keyExtractor={this.keyExtractor}
							showsVerticalScrollIndicator={false}
							// snapToInterval={420}
							// getItemLayout={(data, index) => ({
							// 	length: 400,
							// 	offset: 400 * index,
							// 	index,
							// })}
							numColumns={1}
							maxToRenderPerBatch={9}
							ref={(ref) => (this.flatListRef = ref)}
						/>
					)
				) : (
					<Center>
						<Loader name={"circle"} color={colors.secondary} />
					</Center>
				)}
				{!this.state.product && <ShowBasketButton />}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: { flex: 1, backgroundColor: "#EBEBEB" },
	listContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		paddingBottom: 5,
	},
	shadow: {
		shadowColor: "#fff",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 1.84,

		elevation: 5,
		// overflow: 'hidden',
	},
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(Restaurant));
