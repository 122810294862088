import React from 'react'
import { StyleSheet, View, Image } from 'react-native'
import styled from 'styled-components/native'

const ImageCard = styled.View`
	height: 500px;
	width: 90%;
	background-color: #bdbdbd;
	border: 0.5px solid #f7f7f7;
	border-radius: 10px;
	margin-bottom: 10px;
`

const ButtonCard = styled.View`
	width: 90%;
	border: 0.5px solid #f7f7f7;
	border-radius: 10px;
	background-color: #bdbdbd;
	position: relative;
	display: flex;
	justify-content: flex-end;
	height: 120px;
	overflow: hidden;
`

const Container = styled.View`
	flex: 1;
	max-width: 425px;
	max-height: 800px;
	height: 100%;
	width: 100%;
	align-items: center;
	position: relative;
	overflow: hidden;
`

class HomeGhost extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			opacity: 1,
			prevOpacity: 1,
		}
	}

	static navigationOptions = {
		headerShown: false,
	}

	componentDidMount() {
		this.opacityChange = setInterval(() => {
			let opacity = this.state.opacity
			let prevOpacity = this.state.opacity
			let nextOpacity = opacity
			if (opacity == 1) {
				nextOpacity = 0.95
			} else if (opacity == 0.5) {
				nextOpacity = 0.55
			} else if (opacity > prevOpacity) {
				nextOpacity = opacity + 0.05
			} else {
				nextOpacity = Math.max(opacity - 0.05, 0.5)
			}
			this.setState({
				opacity: nextOpacity,
				prevOpacity: opacity,
			})
		}, 100)
	}

	componentWillUnmount() {
		if (this.opacityChange) clearInterval(this.opacityChange)
	}

	render() {
		return (
			<Container>
				<Image style={styles.img} source={require('@assets/logosplash.png')} />
				{/* <ImageCard style={{ opacity: 1 - this.state.opacity }} />
				<ButtonCard style={{ opacity: 1 - this.state.opacity }} /> */}
			</Container>
		)
	}
}
const styles = StyleSheet.create({
	img: {
		flex: 1,
		width: '100%',
		height: '100%',
		resizeMode: 'cover',
	},
})

const mapStateToProps = (state) => {
	return state
}

export default HomeGhost
