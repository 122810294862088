import React from "react";
import { Text, StyleSheet, View, ScrollView } from "react-native";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Tex, LTex, LSubTitle } from "@utils/styled-components";
import { connect } from "react-redux";
import styled from "styled-components/native";
import { get } from "@utils/api";
import { getTotalPrice } from "@utils/common.js";
import OrderFollowItem from "@components/OrderFollowItem";
import Banniere from "@components/Banniere";
import MyTimer from "@components/MyTimer";
import Loader from "@components/Loader";
import {
	MdReplyAll,
	MdOutlineAccessTime,
	MdFastfood,
	MdCheck,
	MdOutlineCreditCard,
} from "react-icons/md";
import withRouter from "@utils/withRouter";

const HeaderRow = styled.View`
	flex-direction: row;
	height: 80px;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	padding: 20px;
	margin-top: 0px;
	background-color: #fff;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
`;
const TopRow = styled.View`
	position: relative;
	display: flex;
	justify-content: ${(props) => (props.vertical ? "center" : "flex-start")};
	align-items: ${(props) => (props.vertical ? "flex-start" : "center")};
	flex-direction: ${(props) => (props.vertical ? "column" : "row")};
	padding: ${(props) => (props.small ? "20px" : "32px 20px")};
`;
const TrucVertRow = styled.View`
	position: relative;
	display: flex;
	justify-content: space-around;
	align-items: ${(props) => (props.vertical ? "flex-start" : "center")};
	flex-direction: ${(props) => (props.vertical ? "column" : "row")};
	padding: ${(props) => (props.small ? "10px" : "32px 20px")};
	padding-bottom: 20px;
`;
const Title = styled.Text`
	color: #3d3e43;
	font-size: 20px;
	font-weight: bold;
	line-height: 32px;
`;
const Interstice = styled.View`
	background-color: #f2f1f3;
	height: ${(props) => (props.big ? "4px" : "2px")};
	width: 100%;
`;
const PriceRow = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 40px;
	align-items: center;
	padding: 0 10px;
`;
const PriceText = styled.Text`
	color: #3d3e43;
	font-size: 16px;
	line-height: 26px;
	font-weight: ${(props) => (props.bold ? "bold" : "300")};
`;
const MyText = styled.Text`
	color: #3d3e43;
	font-size: 16px;
	font-weight: ${(props) => (props.bold ? "bold" : "300")};
	line-height: 26px;
	padding-left: 10px;
	width: 100%;
`;
const SubT = styled(LTex)`
	color: #3d3e43;
	font-size: 18px;
	font-weight: bold;
`;
const Header = styled.View`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: ${(props) => (props.left ? "flex-start" : "center")};
	background-color: #fdfdfd;
	padding: 10px;
`;
const StatusWrapper = styled.View`
	padding: 0 20px 10px 20px;
`;
const RefundTex = styled(Tex)`
	background-color: #e10079;
	font-size: 18;
	color: white;
	padding: 5px;
`;

class OrderFollow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			readyIn: 0,
			estimatedTimeOrderReady: null,
			orderDateTime: null,
			price: 0,
			status: null,
			timetables: null,
			isPaid: false,
			order: [],
			orderId: this.props.route.params.orderId,
			items: [],
			isStatusLoaded: false,
			restaurantId: null,
			restaurantName: "",
			type: null,
		};
	}

	static navigationOptions = {
		headerShown: false,
	};

	getOrderStatus() {
		get(
			"/order/" + this.state.orderId,
			(res) => {
				console.log("res.order", res.order);
				this.setState({
					readyIn: 0,
					requestedDatetime: res.order.requestedDatetime,
					orderDateTime: res.order.orderDatetime,
					price: res.order.price,
					status: res.order.status,
					isPaid: res.isPaid,
					items: res.items,
					isStatusLoaded: true,
					type: res.order.type,
					restaurantId: res.order.restaurantId,
					restaurantName: res.order.restaurantName,
					displayId: res.order.displayId,
					kitchenStatus: res.order.kitchenStatus,
					maxPreparationTime: res.order.preparationTime,
					table: res.order.tableName,
					comment: res.order.comment,
				});
			},
			(err) => console.warn("err in getting the order status", err)
		);
	}

	statusToTrucVert(colors) {
		const requestedDatetime = dayjs(this.state.requestedDatetime).add(
			this.state.maxPreparationTime,
			"minute"
		);
		// returns html according to the status
		if (this.state.kitchenStatus == "pending") {
			return (
				<View style={{ flex: 1 }}>
					<StatusWrapper>
						<SubT>Commande en attente de règlement</SubT>
					</StatusWrapper>
					<Loader color={colors.secondary} name={"ball-grid-pulse"} />
				</View>
			);
		} else if (this.state.kitchenStatus == "toPrepare") {
			return (
				<View style={{ flex: 1 }}>
					<StatusWrapper>
						<SubT>
							{this.state.timerExpired
								? "Votre commande devrait bientôt être prête"
								: "Commande en cours de préparation"}
						</SubT>
					</StatusWrapper>
					<Loader color={colors.secondary} name={"ball-grid-pulse"} />
				</View>
			);
		} else if (
			this.state.kitchenStatus == "ready" ||
			this.state.kitchenStatus == "done"
		) {
			return (
				<>
					<StatusWrapper>
						<SubT>Votre commande est prête !</SubT>
						<Text>Rendez-vous au comptoir !</Text>
					</StatusWrapper>
				</>
			);
		}
		// else if (this.state.status == 'done') {
		// 	return (
		// 		<>
		// 			<StatusWrapper>
		// 				<SubT>Commande terminée !</SubT>
		// 				<Text>À très bientôt !</Text>
		// 			</StatusWrapper>
		// 			<TrucVertRow small>
		// 				<TrucVert full marg='10px 10px 10px 0' />
		// 				<TrucVert full />
		// 				<TrucVert full marg='10px 0px 10px 10px' />
		// 			</TrucVertRow>
		// 		</>
		// 	)
		// }
		else if (this.state.status == "refused") {
			if (this.state.isPaid) {
				return (
					<StatusWrapper>
						<RefundTex>Remboursée</RefundTex>
						<Tex marg="30px 10px">
							Votre commande a été annulée par le restaurant, nous vous avons
							remboursé la somme de <Tex bold>{this.state.price}€</Tex>.
						</Tex>
						<Tex marg="30px 10px">
							Le remboursement sera effectif d'ici{" "}
							<Tex bold>2 à 3 jours ouvrés</Tex>.
						</Tex>
					</StatusWrapper>
				);
			}
		}
	}

	capitalize(str) {
		return str[0].toUpperCase() + str.slice(1);
	}

	componentDidMount() {
		this.getOrderStatus();
		// this.getRestaurantData();
		this.fetchNewOrder = setInterval(() => {
			this.getOrderStatus();
		}, 60000);
	}

	componentWillUnmount() {
		if (this.fetchNewOrder) clearInterval(this.fetchNewOrder);
	}

	render() {
		const now = dayjs();
		const requestedDatetime = dayjs(this.state.requestedDatetime).add(
			this.state.maxPreparationTime,
			"minute"
		);

		const colors = this.props.appStore.restaurant.style;
		let navigationOrderId = this.props.route.params.orderId;
		if (navigationOrderId != this.state.orderId) {
			this.setState({ orderId: navigationOrderId });
			this.getOrderStatus();
		}

		return (
			<View style={styles.container}>
				<Banniere {...this.props.appStore} />
				{/* <ScrollView
					showsVerticalScrollIndicator={false}
					style={{
						flex: 1,
						paddingTop: 5,
						backgroundColor: 'white',
						position: 'relative',
						zIndex: 1,
						flexDirection: 'column',
					}}
				> */}
				<HeaderRow>
					{/* <MdReplyAll size={25} color={colors.secondary} /> */}
					<LSubTitle margin="5px 0 0 0" style={{ color: colors.primary }}>
						Commande #{this.state.displayId}
						{/* -{getDriveatOrderId(this.state.orderId)} */}
					</LSubTitle>
				</HeaderRow>

				{this.statusToTrucVert(colors)}
				{this.state.kitchenStatus == "toPrepare" && !this.state.timerExpired && (
					<>
						<TopRow small>
							<MdOutlineAccessTime
								style={styles.iconLeft}
								color={colors.secondary}
								size={22}
							/>
							<View
								style={{
									display: "flex",
									flex: 1,
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<MyText>Temps d'attente estimé :</MyText>
								<MyTimer
									timer={requestedDatetime}
									onExpire={() => this.setState({ timerExpired: true })}
								/>
							</View>
						</TopRow>
					</>
				)}

				<TopRow small>
					<Title>Votre commande</Title>
				</TopRow>
				<Interstice />
				<Header>
					{this.state.items.map((item, index) => {
						return <OrderFollowItem item={item} key={index} />;
					})}
				</Header>
				<Interstice style={{ width: "90%", alignSelf: "center" }} />
				<Header>
					{/* <PriceRow>
							<PriceText>Sous-Total</PriceText>
							<PriceText>{getTotalPrice(this.state.items, true)} €</PriceText>
						</PriceRow> */}
					<PriceRow>
						<PriceText bold>Total</PriceText>
						<PriceText bold>
							{getTotalPrice(
								this.state.items,
								true,
								this.state.order.deliveryFees || 0
							)}{" "}
							€
						</PriceText>
					</PriceRow>
				</Header>
				<Interstice />
				{!!this.state.comment && (
					<>
						<TopRow small>
							<MyText style={{ fontStyle: "italic" }}>
								{this.state.comment}
							</MyText>
						</TopRow>
						<Interstice />
					</>
				)}
				<TopRow small>
					<MdFastfood
						style={styles.iconLeft}
						color={colors.secondary}
						size={22}
					/>
					<MyText>
						{{ onSite: "Sur place", takeAway: "À emporter" }[this.state.type]}
						{this.state.table ? " - Table " + this.state.table : ""}
					</MyText>
				</TopRow>
				<Interstice style={{ width: "90%", alignSelf: "center" }} />
				<TopRow small>
					{this.state.isPaid ? (
						<>
							<MdCheck
								style={styles.iconLeft}
								color={colors.secondary}
								size={22}
							/>
							<MyText>Commande payée</MyText>
						</>
					) : (
						<>
							<MdOutlineCreditCard
								style={styles.iconLeft}
								color={colors.secondary}
								size={22}
							/>
							<MyText>Commande à payer au comptoir</MyText>
						</>
					)}
				</TopRow>
				{/* </ScrollView> */}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "white",
	},
	iconLeft: {
		marginRight: 20,
	},
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(OrderFollow));
