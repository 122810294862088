import React from "react";
import {
	Text,
	StyleSheet,
	View,
	Image,
	Dimensions,
	Platform,
} from "react-native";
import styled from "styled-components/native";
import { Center } from "@utils/styled-components";
import { FontAwesome5 } from "@expo/vector-icons";
import { connect } from "react-redux";
import { BASE_URL } from "@env";
import Banniere from "@components/Banniere";
import withRouter from "@utils/withRouter";

const { height, width } = Dimensions.get("window");

const MyCenter = styled(Center)`
	display: flex;
	max-height: 800px;
	max-width: 425px;
	background-color: #f7f7f7;
`;
const BoxId = styled.View`
	height: 80px;
	width: 80px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
`;
const Row = styled.View`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: ${(props) => (props.left ? "flex-start" : "center")};
	background-color: #fff;
	padding: 0px 10px 10px 10px;
	border-radius: 10px;
	margin: 5px;
`;

class OrderNum extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	static navigationOptions = {
		headerShown: false,
	};

	componentDidMount = async () => {
		// setTimeout(() => {
		// 	this.props.navigation.replace("ThankyouScreen", {
		// 		orderId: this.props.basketStore.order.orderId,
		// 	});
		// }, 4000);
	};

	render() {
		// const colors = this.props.appStore.restaurant.style
		return (
			<View style={styles.container}>
				<Banniere {...this.props.appStore} />
				{/* <Image
					style={styles.img}
					source={{
						uri: 'http://res.cloudinary.com/drive-eat/image/fetch/w_500/' + BASE_URL + this.props.appStore.app.logo,
					}}
				/> */}
				<View style={styles.main}>
					<Row>
						<Text style={[styles.text, { color: "#40403F" }]}>
							Votre Commande porte le n°
							{/* #{this.props.basketStore.order.displayId + ''} */}
						</Text>
					</Row>
					<BoxId>
						<Text style={[styles.text, { color: "#40403F" }]}>
							#{this.props.basketStore.order.displayId + ""}
						</Text>
					</BoxId>
					{Platform.OS != "web" && (
						<FontAwesome5 name="receipt" size={25} color={"#ed1c24"} />
					)}
					{this.props.basketStore.payment == "CARD" ? (
						<Row>
							<Text style={[styles.text, { fontSize: 25, color: "#40403F" }]}>
								N'oubliez pas votre ticket !
							</Text>
						</Row>
					) : (
						<Row>
							<Text style={[styles.text, { fontSize: 20, color: "#40403F" }]}>
								Dirigez-vous au comptoir pour régler votre commande !
							</Text>
						</Row>
					)}
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		maxWidth: 425,
		maxHeight: 800,
		alignItems: "center",
	},
	img: {
		flex: 1,
		width: "70%",
		resizeMode: "contain",
	},
	main: {
		flex: 1,
		alignItems: "center",
		textAlign: "center",
		// justifyContent: "space-around",
		padding: 10,
	},
	text: {
		fontSize: 30,
		fontWeight: "bold",
		textAlign: "center",
		paddingVertical: 20,
	},
});
const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(OrderNum));
