// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Store } from "./store/configureStore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	// apiKey: "AIzaSyDX4MIqkd_vz-5mJF791bKWC1AIrKITmlA",
	// authDomain: "wifiorder-744c5.firebaseapp.com",
	// projectId: "wifiorder-744c5",
	// storageBucket: "wifiorder-744c5.appspot.com",
	// messagingSenderId: "230812147496",
	// appId: "1:230812147496:web:af01a256949f733f70fa1d",

	apiKey: "AIzaSyC0RWgfLhXauENnf5uoxWtkxyJc5s9HqfY",
	authDomain: "push-react-native-test.firebaseapp.com",
	projectId: "push-react-native-test",
	storageBucket: "push-react-native-test.appspot.com",
	messagingSenderId: "928873184701",
	appId: "1:928873184701:web:f7254344e7fe381d4d66d4",
};

//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

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
	return getToken(messaging, {
		vapidKey:
			// "BD6SqKy_WQ9Ba9MQFmEBfX45lgb5IZmoVXRzUqybuyxmtR3xCmrJ5oTjbQZB0ITCJ_LtBk3xo5qeNlD7ixl4Bjw",
			"BLCTfxNMKelZRYIHwtOQax_NgmjiyBAm13hah1IKtzDVjv4PDChTUXPi-9PHAy5PXrfFi78CfuVFVstZGAouaRI",
	})
		.then((currentToken) => {
			if (currentToken) {
				Store.dispatch({
					type: "UPDATE_DEVICE_TOKEN",
					values: {
						deviceToken: currentToken,
					},
				});
				console.log("current token for client: ", currentToken);
				return true;
				// Track the token -> client mapping, by sending to backend server
				// show on the UI that permission is secured
			} else {
				console.log(
					"No registration token available. Request permission to generate one."
				);
				return false;
				// shows on the UI that permission is required
			}
		})
		.catch((err) => {
			console.log("An error occurred while retrieving token. ", err);
			// catch error while creating client token
		});
};

export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload);
		});
	});
