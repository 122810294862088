import React from "react";
import {
	StyleSheet,
	Text,
	View,
	Platform,
	BackHandler,
	Alert,
} from "react-native";
import { Provider } from "react-redux";
import { Store } from "./store/configureStore";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import MainTabNavigator from "./navigation/mainTabNavigator";
import AppLoading from "expo-app-loading";
import HomeGhost from "@components/homeGhost";
import * as Font from "expo-font";
import { StatusBar } from "expo-status-bar";
import NavigationService from "./navigation/navigationService";
import Loader from "@components/Loader";
import { Center } from "@utils/styled-components";
import defaultColors from "@utils/colors";

import { fetchToken, onMessageListener } from "./firebase";

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isOnline: true,
			isLocationEnabled: false,
		};
	}

	_handleFinishLoading = () => {
		this.setState({ isLoading: false });
	};

	_handleLoadingError = (err) => {
		//console.error(err);
	};

	componentDidMount() {
		fetchToken();
		onMessageListener().then((payload) => {
			console.log("Message received", payload);
			NavigationService.navigate("OrderFollowScreen", {
				appId: payload.appId,
				orderId: payload.orderFollow,
			});
		});
	}

	// _loadResourcesAsync = async () => {
	// 	await Promise.all([
	// 		Font.loadAsync({
	// 			//...Icon.Ionicons.font,
	// 			"SFUIText-Medium": require("./assets/fonts/sf-ui-display-medium-58646be638f96.otf"),
	// 			"SFUIText-Bold": require("./assets/fonts/sf-ui-display-bold-58646a511e3d9.otf"),
	// 			"SFUIText-Light": require("./assets/fonts/sf-ui-display-light-58646b33e0551.otf"),
	// 			"SFUIText-Semibold": require("./assets/fonts/sf-ui-display-semibold-58646eddcae92.otf"),
	// 		}),
	// 	]);
	// };

	// gets the current screen from navigation state
	getActiveRouteName(navigationState) {
		if (!navigationState) {
			return null;
		}
		const route = navigationState.routes[navigationState.index];
		// dive into nested navigators
		if (route.routes) {
			return this.getActiveRouteName(route);
		}
		return route.routeName;
	}

	render() {
		let persistor = persistStore(Store);
		let _that = this;
		// if (this.state.isLoading) {
		// 	return (
		// 		<Center>
		// 			<Loader name={"circle"} color={defaultColors.secondary} />
		// 		</Center>
		// 	);
		// } else {
		return (
			<View
				style={{
					flex: 1,
					justifyContent: "center",
					maxWidth: 425,
					width: "100%",
					maxHeight: 800,
					margin: "auto",
				}}
			>
				<Provider store={Store}>
					{Platform.OS === "android" ? (
						<StatusBar
							StatusBarStyle={{ color: "#FFF" }}
							hidden={false}
							style='light'
							backgroundColor='rgb(64, 64, 63)'
						/>
					) : null}
					<PersistGate persistor={persistor} loading={<HomeGhost />}>
						<MainTabNavigator
							ref={(navigatorRef) => {
								NavigationService.setTopLevelNavigator(navigatorRef);
							}}
							onNavigationStateChange={(prevState, currentState, action) => {
								const currentRouteName = _that.getActiveRouteName(currentState);
								const previousRouteName = _that.getActiveRouteName(prevState);

								if (previousRouteName !== currentRouteName) {
								}
							}}
						/>
					</PersistGate>
				</Provider>
			</View>
		);
	}
	// }
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
	loaderWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		padding: 30,
	},
});
