import React from "react";
import { StyleSheet, TouchableOpacity, Platform } from "react-native";
import { connect } from "react-redux";
import { WhiteText } from "@utils/styled-components";
import withRouter from "@utils/withRouter";

class ShowBasketButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bottom: 0,
		};
	}

	onPress() {
		this.props.navigation.navigate("BasketScreen", {
			appId: this.props.appStore.app.appId,
		});
	}

	render() {
		const colors = this.props.appStore.restaurant.style;
		if (
			this.props.basketStore.restaurantId &&
			this.props.basketStore.items.length > 0
		) {
			return (
				<TouchableOpacity
					style={[
						styles.wrapper,
						{ bottom: this.state.bottom, backgroundColor: colors.secondary },
					]}
					onPress={() => this.onPress()}
				>
					<WhiteText fontSize="18px" bold>
						Voir mon panier
					</WhiteText>
				</TouchableOpacity>
			);
		} else return null;
	}
}

const styles = StyleSheet.create({
	wrapper: {
		width: "85%",
		marginLeft: "7.5%",
		marginRight: "7.5%",
		backgroundColor: "#DA3539",
		height: 55,
		zIndex: 1000,
		position: Platform.OS === "web" ? "sticky" : "absolute",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 15,
	},
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(ShowBasketButton));
