import { Persistor, Store } from "../store/configureStore";
import { API_URL } from "@env";

const get = (uri, successCallback, failureCallback) => {
	return fetch(API_URL + uri, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + Store.getState().authStore.token,
		},
	})
		.then((response) => {
			return response.json().then((json) => {
				return successCallback(json);
			});
		})
		.catch((err) => {
			console.log("ERROR IS " + err);
			return failureCallback(err);
		});
};

const post = (uri, data, successCallback, failureCallback) => {
	return fetch(API_URL + uri, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + Store.getState().authStore.token,
		},
		body: JSON.stringify(data),
	})
		.then((response) => {
			if (response.status >= 400) {
				if (failureCallback) return failureCallback(response);
			} else {
				return response
					.json()
					.then((json) => {
						return successCallback(json);
					})
					.catch(() => {
						return failureCallback();
					});
			}
		})
		.catch((err) => {
			console.log(uri);
			console.log(err);
			if (failureCallback) return failureCallback(err);
		});
};

module.exports = { get, post };
