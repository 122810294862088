import React from "react";
import { StyleSheet, View, Image, Text } from "react-native";
import styled from "styled-components/native";
import {
	StyledButton,
	Center,
	Tex,
	LSubTitle,
	WhiteText,
} from "@utils/styled-components";
import { connect } from "react-redux";
import Banniere from "@components/Banniere";
import withRouter from "@utils/withRouter";

import { post } from "@utils/api";

const MyCenter = styled(Center)`
	padding: 25px;
	display: flex;
	max-height: 800px;
	max-width: 425px;
	background-color: #40403f;
`;

const Half = styled(Center)`
	flex: 1;
	background-color: #40403f;
`;
const BottomRow = styled.View`
	height: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Row = styled.View`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: ${(props) => (props.left ? "flex-start" : "center")};
	background-color: #fff;
	padding: 0px 10px 10px 10px;
	border-radius: 10px;
	margin: 5px;
`;
const BoxId = styled.View`
	height: 80px;
	width: 80px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
`;

class ThankYou extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			displayId: this.props.basketStore.order.displayId,
		};
	}

	static navigationOptions = {
		headerShown: false,
	};

	componentDidMount() {
		setTimeout(async () => {
			let orderId = this.props.route.params.orderId;
			this.sendMail(orderId);
			this.props.navigation.replace("OrderFollowScreen", {
				orderId,
			});
			let action = {
				type: "CLEAR_BASKET",
			};
			this.props.dispatch(action);
		}, 4000);
	}

	sendMail = (orderId) => {
		post(
			"/sendOrderMail",
			{
				orderId,
			},
			async (res) => {},
			(err) => {
				console.warn("payinWithMangopay err", err);
			}
		);
	};

	render() {
		return (
			<View style={styles.container}>
				<Banniere {...this.props.appStore} />
				{/* <Image
					style={styles.img}
					source={{
						uri: 'http://res.cloudinary.com/drive-eat/image/fetch/w_500/' + BASE_URL + this.props.appStore.app.logo,
					}}
				/> */}
				<View style={styles.main}>
					<Row>
						<Text style={[styles.text, { color: "#40403F" }]}>
							MERCI POUR VOTRE COMMANDE !
						</Text>
					</Row>
					<Row>
						<Text style={[styles.text, { color: "#40403F" }]}>
							Votre Commande porte le n° #{this.state.displayId + ""}
							{/* #{this.props.basketStore.order.displayId + ''} */}
						</Text>
					</Row>
					<BoxId></BoxId>
					{this.props.basketStore.payment == "CARD" ? (
						<Row>
							<Text style={[styles.text, { fontSize: 25, color: "#40403F" }]}>
								NOUS COMMENCONS LA PREPARATION DE VOTRE COMMANDE
							</Text>
						</Row>
					) : (
						<Row>
							<Text style={[styles.text, { fontSize: 20, color: "#40403F" }]}>
								Dirigez-vous au comptoir pour régler votre commande !
							</Text>
						</Row>
					)}
				</View>
				{/* <BottomRow>
					<StyledButton
						onPress={() => {
							console.log(this.props.navigation.getParam('orderId'))
							this.props.navigation.navigate('OrderFollowScreen', {
								orderId: this.props.navigation.getParam('orderId'),
							})
						}}
					>
						<WhiteText bold>Suivre ma commande</WhiteText>
					</StyledButton>
				</BottomRow> */}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		maxWidth: 425,
		maxHeight: 800,
		alignItems: "center",
	},
	img: {
		flex: 1,
		width: "70%",
		resizeMode: "contain",
	},
	main: {
		flex: 1,
		alignItems: "center",
		textAlign: "center",
		justifyContent: "space-around",
		padding: 10,
	},
	text: {
		fontSize: 30,
		fontWeight: "bold",
		textAlign: "center",
		paddingVertical: 20,
	},
});
const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(ThankYou));
