import { createStore } from 'redux'
// _WARNING_ redux-persist has been downgraded in order to work on expo
import { persistCombineReducers, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AsyncStorage } from 'react-native'

import auth from './reducers/auth'
import app from './reducers/application.js'
import user from './reducers/user'
import favourites from './reducers/favourites.js'
import basket from './reducers/basket.js'
import { combineReducers } from 'redux'

const mainPersistConfig = {
	key: 'root',
	storage,
}

const Store = createStore(
	persistCombineReducers(mainPersistConfig, { authStore: auth, userStore: user, favouritesStore: favourites, appStore: app, basketStore: basket })
)

module.exports = {
	Store,
}
