import Banniere from "@components/Banniere";
import Loader from "@components/Loader";
import { Center } from "@utils/styled-components";
import withRouter from "@utils/withRouter";
import React from "react";
import { Text, View, StyleSheet } from "react-native";
import { connect } from "react-redux";
import styled from "styled-components/native";
import { get, post } from "@utils/api";

const Row = styled.View`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: ${(props) => (props.left ? "flex-start" : "center")};
	background-color: #fff;
	padding: 0px 10px 10px 10px;
	border-radius: 10px;
	margin: 5px;
`;
class VerifyPayment extends React.Component {
	constructor(props) {
		super(props);
	}

	static navigationOptions = {
		headerShown: false,
	};

	async componentDidMount() {
		if (this.props.route.params && this.props.route.params.transactionId) {
			await this.validatePayment(this.props.route.params.transactionId);
		}
	}

	validatePayment = async (transactionId) => {
		return get(
			"/validateOrder?transactionId=" + transactionId + "&noHtml=1",
			(res) => {
				if (res.status == "SUCCEEDED") {
					// this.validateOrder();
					this.props.navigation.replace("ThankyouScreen", {
						orderId: res.orderId,
					});
				} else {
					this.props.navigation.replace("BasketScreen", { paymentFailed: 1 });
				}
			},
			(err) => {
				console.warn("payinWithMangopay err", err);
			}
		);
	};

	// validateOrder = () => {
	// 	return post(
	// 		"/order",
	// 		{
	// 			orderId: this.props.basketStore.order.orderId,
	// 			restaurantId: this.props.appStore.restaurant.restaurantId,
	// 			appId: this.props.appStore.app.appId,
	// 			status: "done",
	// 			kitchenStatus: "toPrepare",
	// 		},
	// 		async (res) => {
	// 			let action = {
	// 				type: "SAVE_ORDER",
	// 				values: {
	// 					order: res.order,
	// 				},
	// 			};

	// 			this.props.dispatch(action);

	// 			this.props.navigation.replace("ThankyouScreen");
	// 		},
	// 		(err) => {
	// 			console.warn("err in posting the order", err);
	// 			this.setState({
	// 				loading: false,
	// 			});
	// 		}
	// 	);
	// };

	render() {
		const colors = this.props.appStore.restaurant.style;
		return (
			<View style={styles.container}>
				<Banniere {...this.props.appStore} />
				<View style={styles.main}>
					<Row>
						<Text style={[styles.text, { color: "#40403F" }]}>
							VEUILLEZ PATIENTER
						</Text>
					</Row>
					<Center>
						<Loader name={"circle"} color={colors.secondary} />
					</Center>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		maxWidth: 425,
		maxHeight: 800,
		alignItems: "center",
	},
	img: {
		flex: 1,
		width: "70%",
		resizeMode: "contain",
	},
	main: {
		flex: 1,
		alignItems: "center",
		textAlign: "center",
		justifyContent: "space-around",
		padding: 10,
	},
	text: {
		fontSize: 30,
		fontWeight: "bold",
		textAlign: "center",
		paddingVertical: 20,
	},
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(VerifyPayment));
