import React from "react";
import styled from "styled-components/native";
import { connect } from "react-redux";
import { LSubTitle } from "@utils/styled-components";
import { MdReplyAll } from "react-icons/md";
import withRouter from "@utils/withRouter";

const HeaderRow = styled.TouchableOpacity`
	flex-direction: row;
	margin-top: 0px;
	height: 70px;
	width 100%;
	justify-content: flex-start;
	align-items: center;
	padding: 20px 20px 15px 20px;
	background-color: white;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
`;

class TopHeader extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const colors = this.props.appStore.restaurant.style;
		return (
			<HeaderRow
				onPress={() => {
					this.props.navigation.navigate(
						this.props.destination,
						this.props.params
					);
				}}
			>
				<MdReplyAll size={25} color={colors.primary} />
				<LSubTitle
					margin="5px 0 0 10px"
					color={colors.primary}
					lineHeight="30px"
				>
					{this.props.title}
				</LSubTitle>
			</HeaderRow>
		);
	}
}

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(TopHeader));
