import React from "react";
import { StyleSheet, View, Image } from "react-native";
import { connect } from "react-redux";
import styled from "styled-components/native";
import { LTex, Tex } from "@utils/styled-components";

const Card = styled.TouchableOpacity`
	width: 100%;
	display: flex;
	padding: 10px;
	border-radius: 10px;
	margin-vertical: 5px;
	background-color: #ffffff;
	elevation: 3;
`;
const Row = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;
const ItemName = styled(LTex)`
	color: #40403f;
	font-size: 16px;
	font-weight: 700;
	line-height: 16px;
`;
const Item = styled(LTex)`
	color: #646564;
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
`;
const Supplement = styled(LTex)`
	color: #A6A5A6;	
	font-size: 14px;	
	font-weight: 500;	
	line-height: 20px;}
`;
const NumberCircle = styled.TouchableOpacity`
	height: 30px;
	width: 30px;
	border-width: 1px;
	border-radius: 10px;
	border-color: #ddd;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
`;

const OrderFollowItem = ({ item }) => {
	// receives an 'item' as props

	const getItemPrice = () => {
		let itemPrice = parseFloat(item.price);
		return itemPrice * item.quantity;
	};

	console.log(item);
	return (
		<Card style={styles.shadow} onPress={() => {}}>
			<Row>
				<View style={{ flex: 2, flexDirection: "row", alignItems: "center" }}>
					{item.image ? (
						<Image
							source={{
								uri:
									"http://res.cloudinary.com/drive-eat/image/fetch/w_100/" +
									item.image,
							}}
							style={styles.img}
						/>
					) : null}
					<ItemName numberOfLines={1}>
						<LTex color="#ed1c24">{item.quantity}x </LTex>
						{item.name}
					</ItemName>
				</View>
				<View
					style={{
						flex: 0.7,
						alignItems: "flex-end",
					}}
				>
					<LTex bold> {getItemPrice(item).toFixed(2)} €</LTex>
				</View>
			</Row>
			<View style={styles.bitOfPadding}>
				{item.orderMenuItems &&
					item.orderMenuItems.length > 0 &&
					item.orderMenuItems.map((mpi, index) => {
						return mpi.priceIfSupplement > 0 ? (
							<View key={`mpi-${index}`} style={{ flexDirection: "row" }}>
								<Supplement>
									+ {mpi.quantity > 1 ? mpi.quantity : ""} {mpi.product.name}{" "}
								</Supplement>
								<Supplement>
									(
									{(
										parseFloat(mpi.priceIfSupplement) *
										(mpi.product.supplement
											? mpi.quantity
											: mpi.quantitySupplement || 1)
									).toFixed(2)}{" "}
									€)
								</Supplement>
							</View>
						) : (
							<Item key={`mpi-${index}`}>
								{mpi.quantity > 1 ? mpi.quantity : ""} {mpi.product.name}
							</Item>
						);
					})}
			</View>
		</Card>
	);
};

const styles = StyleSheet.create({
	img: {
		width: 30,
		height: 30,
		marginRight: 0,
		resizeMode: "contain",
	},
	shadow: {
		shadowColor: "#FFF",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.34,
		shadowRadius: 3.84,

		elevation: 1,
		// overflow: 'hidden',
	},
	bitOfPadding: { paddingLeft: 25 },
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(OrderFollowItem);
