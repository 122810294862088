const initialState = { favourites: [] };

function favourites(state = initialState, action) {
	let nextState = { ...state };
	switch (action.type) {
		case "UPDATE_FAVOURITES":
			nextState.favourites = action.value;
			return nextState;
		case "NEW_FAVOURITE":
			nextState.favourites.push(action.value);
			return nextState;
		default:
			return state;
	}
}

export default favourites;
