import React from "react";
import { View, StyleSheet } from "react-native";
import styled from "styled-components/native";
import { LTex, Center } from "@utils/styled-components";
import TopHeader from "@components/TopHeader";
import UserMenuItem from "@components/userMenuItem";
import Loader from "@components/Loader";
import { post } from "@utils/api";
import { connect } from "react-redux";
import Banniere from "@components/Banniere";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import withRouter from "@utils/withRouter";

const Main = styled.View`
	display: flex;
	padding: 20px;
`;
class PaymentChoice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			order: null,
			loading: false,
			restaurant: null,
		};
	}

	static navigationOptions = {
		headerShown: false,
	};

	componentDidMount = async () => {
		const cashEnabled =
			this.props.appStore.app.paymentModes &&
			this.props.appStore.app.paymentModes
				.split(",")
				.find((elt) => elt == "cash");
		const cbEnabled =
			this.props.appStore.app.paymentModes &&
			this.props.appStore.app.paymentModes
				.split(",")
				.find((elt) => elt == "cb");

		if (this.props.basketStore.isFree || (cashEnabled && !cbEnabled)) {
			let action = {
				type: "CHANGE_PAYMENT",
				values: { payment: "CASH" },
			};
			this.props.dispatch(action);
			this.setState({ payment: "CASH" }, () => this.validateOrder());
		}
	};
	validateOrder() {
		this.setState({
			validateBasket: true,
			loading: true,
		});

		post(
			"/order",
			{
				...this.props.basketStore,
				...this.props.basketStore.items,
				payment: this.state.payment == "CASH" ? "CASH" : null,
				orderId: this.props.basketStore.order
					? this.props.basketStore.order.orderId
					: null,
				tableName: this.props.basketStore.tableName,
				comment: this.props.basketStore.comment,
				totalPrice: parseFloat(this.props.basketStore.totalPrice),
				restaurantId: this.props.appStore.restaurant.restaurantId,
				appId: this.props.appStore.app.appId,
				requestedTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
				orderTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
				user: this.props.authStore.user || {},
				status:
					this.state.payment &&
					!this.props.appStore.restaurant.hasCaisse == "CASH"
						? "done"
						: "created",
				kitchenStatus:
					this.state.payment &&
					!this.props.appStore.restaurant.hasCaisse == "CASH"
						? "toPrepare"
						: "pending",
			},
			async (res) => {
				console.log("new order", res);
				let action = {
					type: "SAVE_ORDER",
					values: {
						order: res.order,
					},
				};

				this.props.dispatch(action);

				// let printed = await this.print(res.order)
				// if (printed) {
				// 	post(
				// 		'/printOrderTicket/' + res.order.orderId,
				// 		{
				// 			ticketType: 'ticket',
				// 			tickets: [
				// 				{
				// 					ticketType: 'ticket',
				// 					appType: 'borne',
				// 				},
				// 			],
				// 		},
				// 		(res) => {
				// 			console.log('printOrderTicket success')
				// 		},
				// 		(err) => {
				// 			console.error('printOrderTicket error', err)
				// 		}
				// 	)
				// }

				if (this.state.payment == "CARD") {
					// this.props.navigation.navigate("PayWithCardScreen", {
					// 	appId: this.props.appStore.app.appId,
					// });
					this.payinWithMangopay();
				} else {
					setTimeout(() => {
						this.setState({
							loading: false,
						});
						this.props.navigation.navigate("ThankyouScreen", {
							appId: this.props.appStore.app.appId,
							orderId: this.props.basketStore.order.orderId,
						});
					}, 3000);
				}
			},
			(err) => {
				console.warn("err in posting the order", err);
				this.setState({
					loading: false,
				});
			}
		);
	}

	payinWithMangopay() {
		let paymentData = {
			orderId: this.props.basketStore.order.orderId,
			returnUrl:
				window.location.origin +
				"/check-paiement?appId=" +
				this.props.appStore.app.appId +
				"&orderId=" +
				this.props.basketStore.order.orderId,
		};

		post(
			"/webPayInMangopay",
			paymentData,
			(res) => {
				console.warn("payinWithMangopay ok", res.paymentResult.RedirectURL);
				window.location = res.paymentResult.RedirectURL;
				// this.setState({
				// 	secureUrl: res.paymentResult.RedirectURL,
				// })
			},
			(err) => {
				console.warn("payinWithMangopay err", err);
				err.json().then((json) => {
					// json here is the error data
					// if (json.paymentResult && json.paymentResult.Status === 'FAILED') {
					Alert.alert(
						"Une erreur est survenue",
						json.paymentResult.ResultMessage +
							"\nCode d'erreur: " +
							json.paymentResult.ResultCode +
							"\nLe paiement n'a pas été effectué. \nVeuillez réessayer plus tard ou contacter le support.",
						[
							{
								text: "Réessayer",
								onPress: () => this.makePayment(),
							},
						],
						{ cancelable: false }
					);
					// }
				});
			}
		);
	}

	render() {
		const colors = this.props.appStore.restaurant.style;
		const cashEnabled =
			this.props.appStore.app.paymentModes &&
			this.props.appStore.app.paymentModes
				.split(",")
				.find((elt) => elt == "cash");
		const cbEnabled =
			this.props.appStore.app.paymentModes &&
			this.props.appStore.app.paymentModes
				.split(",")
				.find((elt) => elt == "cb");
		if (!this.props.basketStore.items.length && this.props.isFocused) {
			this.props.navigation.replace("RestaurantScreen", {
				appId: this.props.appStore.app.appId,
			});
		}

		return (
			<View style={styles.container}>
				<Banniere {...this.props.appStore} />
				{this.props.basketStore.isFree || this.state.loading ? (
					<Center>
						<Loader name={"circle"} color={colors.secondary} />
					</Center>
				) : (
					<View style={styles.main}>
						<TopHeader title="Paiement" destination="BasketScreen" />
						<Main>
							<LTex fontSize="17px" marg="0 0 10px 0">
								Sélectionnez un mode de paiement
							</LTex>
							{cbEnabled && (
								<UserMenuItem
									cb
									bold
									top
									bottom
									params={{ orderId: this.state.orderId, isFromPayment: 1 }}
									big
									icon="credit-card"
									title={"CARTE BANCAIRE"}
									onpress={() => {
										let action = {
											type: "CHANGE_PAYMENT",
											values: { payment: "CARD" },
										};
										this.props.dispatch(action);
										this.setState({ payment: "CARD" }, () =>
											this.validateOrder()
										);
									}}
								/>
							)}
							{cashEnabled && (
								<UserMenuItem
									cash
									bold
									top
									bottom
									big
									icon="handshake-o"
									title="AU COMPTOIR"
									onpress={() => {
										let action = {
											type: "CHANGE_PAYMENT",
											values: { payment: "CASH" },
										};
										this.props.dispatch(action);
										this.setState({ payment: "CASH" }, () =>
											this.validateOrder()
										);
									}}
								/>
							)}
						</Main>
					</View>
				)}
			</View>
		);
	}
}
const mapStateToProps = (state) => {
	return state;
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#f7f7f7",
	},
	main: {
		marginTop: 30,
	},
});

export default connect(mapStateToProps)(withRouter(PaymentChoice));
