import React from 'react'
import { Text, View, StyleSheet, Platform } from 'react-native'
import WebView from 'react-native-webview'
import { get } from '@utils/api'

class SecureView extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			opened: false,
			url: null,
		}
	}

	componentDidMount() {
		this.checkPayment()
	}

	checkPayment = () => {
		this.interval = setInterval(() => {
			get(
				'/validateOrder?noHtml=1&orderId=' + this.props.orderId,
				(res) => {
					if (res.status == 'SUCCEEDED' || res.status == 'FAILED') {
						clearInterval(this.interval)
						console.log(res.status)
						this.props.closeSecureView(res.success)
					}
				},
				(err) => {
					console.error(err)
				}
			)
		}, 5000)
	}

	render() {
		return Platform.OS === 'web' ? (
			<iframe
				id='payment-iframe'
				style={{ width: '100%', height: '100%', maxHeight: 800, maxWidth: 425 }}
				src={this.props.url}
				onLoad={() => {}}
			/>
		) : (
			<View
				style={{
					paddingTop: 20,
					flex: 1,
					width: '100%',
					height: '100%',
					maxWidth: 425,
					maxHeight: 800,
				}}
			>
				<WebView
					style={{
						flex: 1,
						width: '100%',
						height: '100%',
					}}
					source={{ uri: this.props.url }}
					// startInLoadingState={true}
					// onNavigationStateChange={(state) => {
					// 	if (
					// 		state.title &&
					// 		(state.title == "Success" || state.title == "Failed")
					// 	) {
					// 		this.props.closeSecureView(state.title);
					// 	}
					// }}
				/>
			</View>
		)
	}
}

export default SecureView

let styles = StyleSheet.create({
	container: {
		flex: 1,
		width: '100%',
		height: '100%',
	},
	webView: {
		flex: 1,
		width: '100%',
		height: '100%',
	},
})
