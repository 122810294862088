import React from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { connect } from 'react-redux'
import styled from 'styled-components/native'
import { LTex } from '@utils/styled-components'

// Affichage photo à droite
const Wrapper = styled.TouchableOpacity`
	height: 170px;
	width: 97%;
	padding: 10px 10px;
	background-color: white;
	margin-vertical: 2px;
	border-bottom-width: 0.5px;
	border-bottom-color: #ddd;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`
const Indisponible = styled(LTex)`
	color: #fff;
	font-size: 17px;
	font-weight: bold;
	line-height: 15px;
`
const IndisponibleArea = styled.View`
	position: absolute;
	top: 45%;
	align-self: center;
	border-radius: 10px;
	background-color: #9da1a2;
	height: 40px;
	width: 160px;
	align-items: center;
	justify-content: center;
`

class MenuItem extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const colors = this.props.appStore.restaurant.style
		return (
			<Wrapper
				disabled={this.props.disabled}
				ref={(i) => {
					if (Object.keys(this.props.refArr).indexOf(this.props.categoryName) == -1) {
						this.props.refArr[this.props.categoryName] = i
					}
					return i
				}}
				key={this.props.index}
				onPress={() => this.props.onPress()}
			>
				<View style={styles.container}>
					<View style={styles.productContainer}>
						<LTex style={{ color: colors.primary, fontSize: 16 }} bold numberOfLines={2}>
							{this.props.name}
						</LTex>
						<LTex fontSize='13px' numberOfLines={3}>
							{this.props.desc}
						</LTex>
						{this.props.price > 0 && <LTex style={{ color: colors.primary, fontSize: 15 }}>{this.props.price} €</LTex>}
					</View>
					<View style={styles.imageContainer}>
						<Image
							style={styles.img}
							source={{
								uri: 'https://res.cloudinary.com/drive-eat/image/fetch/w_300/' + this.props.image,
							}}
						/>
					</View>
				</View>
				{this.props.disabled ? (
					<IndisponibleArea>
						<Indisponible>INDISPONIBLE</Indisponible>
					</IndisponibleArea>
				) : null}
			</Wrapper>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		width: '100%',
	},
	productContainer: {
		width: '55%',
		justifyContent: 'space-around',
		marginHorizontal: 5,
	},
	imageContainer: {
		width: '45%',
		alignItems: 'center',
		justifyContent: 'center',
		height: 120,
		width: 150,
		borderRadius: 10,
	},
	img: {
		flex: 1,
		borderRadius: 10,
		width: '100%',
		resizeMode: 'contain',
	},
})

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps)(MenuItem)
