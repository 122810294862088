const initialState = {
	menuItemSelected: 'Plats',
	restaurant: {},
	app: {},
}

function app(state = initialState, action) {
	let nextState = { ...state }
	switch (action.type) {
		case 'CHANGE_MENU':
			nextState.menuItemSelected = action.itemSelected
			nextState.filter = action.filter
			return nextState
		case 'SAVE_DATA':
			for (const data in action.values) {
				nextState[data] = action.values[data]
			}
			return nextState
		default:
			return state
	}
}

export default app
