const initialState = {location: null, address: null};

function user(state = initialState, action) {
	let nextState = { ...state };
	switch (action.type) {
		case "USER_LOCATION_SUCCESS":
			nextState.location = action.value;
			return nextState;
		case "USER_ADDRESS_SUCCESS":
			nextState.address = action.value;
			return nextState;	
		default:
			return state;
	}
}

export default user;
