import { post, get } from './api'

module.exports = {
	getTotalPrice: (items, ttc = true, discount) => {
		let totalPrice = 0
		for (let item of items) {
			// if (!isOrder) {
			// 	percentAugmentationProducts = item.percentAugmentationProducts
			// }

			let itemPrice = parseFloat(item.price) * item.quantity
			if (!ttc) {
				itemPrice = itemPrice / (1 + parseFloat(item.TVA) * 0.01)
			}
			totalPrice += itemPrice
			// if (item.selectedItems) {
			// 	for (let it of item.selectedItems) {
			// 		if (it.supplement && !isOrder) {
			// 			var newPrice = (Math.round((1 + parseFloat(percentAugmentationProducts) / 100) * parseFloat(it.price) * 10) / 10).toFixed(2)
			// 			totalPrice = parseFloat(totalPrice) + parseFloat(it.newPrice) * item.quantity
			// 		}
			// 	}
			// }
		}
		return (totalPrice - parseFloat(discount || 0)).toFixed(2)
	},
	getCurrentWalletBalance: (walletId) => {
		// since transfers are made when the restaurant accept the order, the current wallet balance has to be aware of the created orders

		return post(
			'/getCurrentWalletBalance',
			{ walletId },
			(res) => {
				if (res.balance) {
					return res.balance
				} else return null
			},
			(err) => {
				console.warn(err)
			}
		)
	},
	displayLimitedName: (name, length = 30) => {
		return name ? (name.length > length ? name.substr(0, length - 2) + '...' : name) : ''
	},
}
