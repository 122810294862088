import React from "react";
import { View, StyleSheet, Image } from "react-native";
import styled from "styled-components/native";
import { connect } from "react-redux";
import { MdCreditCard, MdEuro } from "react-icons/md";
import withRouter from "@utils/withRouter";

const Row = styled.TouchableOpacity`
	width: 100%;
	padding: 10px 20px;
	height: ${(props) => (props.big ? "80px" : "50px")};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-color: #f7f7f7;
	border-top-width: 0.5px;
	align-items: center;
	background-color: #fff;
	border-top-left-radius: ${(props) => (props.top ? "20px" : "0px")};
	border-top-right-radius: ${(props) => (props.top ? "20px" : "0px")};
	border-bottom-left-radius: ${(props) => (props.bottom ? "20px" : "0px")};
	border-bottom-right-radius: ${(props) => (props.bottom ? "20px" : "0px")};
	margin: 10px 0;
`;

const RowText = styled.Text`
	margin-left: 10px;
	color: ${(props) => (props.blue ? "#107CFF" : "#3D3E43")};
	font-size: 16px;
	font-weight: ${(props) => (props.bold ? "bold" : "300")};
	line-height: 26px;
`;

class UserMenuItem extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const colors = this.props.appStore.restaurant.style;
		return (
			<Row
				disabled={this.props.disabled}
				big={this.props.big}
				top={this.props.top}
				bottom={this.props.bottom}
				onPress={() => {
					this.props.screen
						? this.props.navigation.navigate(
								this.props.screen,
								this.props.params
						  )
						: this.props.onpress();
				}}
			>
				<View style={styles.container}>
					{this.props.cb && <MdCreditCard size={24} color={colors.secondary} />}
					{this.props.cash && <MdEuro size={24} color={colors.secondary} />}
					<RowText blue={this.props.blue} bold={this.props.bold}>
						{this.props.title}
					</RowText>
				</View>
			</Row>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(UserMenuItem));
