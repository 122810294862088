import React from "react";
import { Image, StyleSheet } from "react-native";
import { BASE_URL } from "@env";
import styled from "styled-components/native";

const Wrapper = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 150;
	background-color: #fff;
	border-bottom-width: 10;
	border-bottom-color: #ddd;
`;

export default function Banniere(props) {
	return (
		<Wrapper>
			{props.app.banniere ? (
				<Image
					style={styles.image}
					source={{
						uri:
							"https://res.cloudinary.com/drive-eat/image/fetch/w_1000/" +
							BASE_URL +
							props.app.banniere,
					}}
				/>
			) : (
				<Image style={styles.image} source={require("@assets/Bannière.png")} />
			)}
		</Wrapper>
	);
}

const styles = StyleSheet.create({
	image: {
		flex: 1,
		width: "100%",
		height: "100%",
	},
});
