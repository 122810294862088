import React from "react";
import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { connect } from "react-redux";
import { LTex } from "@utils/styled-components";
import { MdOutlineShoppingBag, MdRestaurantMenu } from "react-icons/md";
import withRouter from "@utils/withRouter";

const Row = styled.TouchableOpacity`
	width: 40%;
	padding: 10px 5px;
	height: 45px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: ${(props) =>
		props.disabled ? "#f7f7f7" : props.selected ? props.color : "#FFF"};
	border-radius: 10px;
	margin: 10px 0;
`;

const RowText = styled(LTex)`
	color: ${(props) =>
		props.disabled ? "#000" : props.selected ? "#FFF" : "#3d3e43"};
	font-size: 15px;
	font-weight: ${(props) =>
		props.bold ? "bold" : props.selected ? "bold" : "300"};
	line-height: 18px;
	padding-left: 6px;
`;

class BasketSettingItem extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const colors = this.props.appStore.restaurant.style;
		return (
			<Row
				color={colors.secondary}
				style={styles.shadow}
				disabled={this.props.disabled}
				selected={this.props.selected}
				onPress={() => {
					this.props.onpress();
				}}
			>
				{this.props.icons && (
					<MdOutlineShoppingBag
						size={20}
						color={this.props.selected ? "#FFF" : colors.secondary}
					/>
				)}
				{this.props.icon && (
					<MdRestaurantMenu
						size={20}
						color={this.props.selected ? "#FFF" : colors.secondary}
					/>
				)}

				<RowText
					disabled={this.props.disabled}
					selected={this.props.selected}
					bold={this.props.bold}
				>
					{this.props.title}
				</RowText>
			</Row>
		);
	}
}

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#FFF",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.34,
		shadowRadius: 3.84,

		elevation: 5,
		// overflow: 'hidden',
	},
});
const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(BasketSettingItem));
