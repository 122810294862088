import dayjs from 'dayjs'
import 'dayjs/locale/fr'

const initialState = {
	restaurantId: null,
	items: [],
	mode: 'onSite',
	tableName: '',
	requestedTime: dayjs(),
	basketResult: {},
}

function basket(state = initialState, action) {
	let nextState = { ...state }
	let momentNow = dayjs().format('YYYY-MM-DD HH:mm:ss')
	let now = dayjs()
	let nowString = now.valueOf()
	switch (action.type) {
		case 'ADD_TO_BASKET':
			nextState.restaurantId = action.values.restaurantId
			nextState.restaurantName = action.values.restaurantName
			let item = action.values.item
			// item.timestamp = Date.now()
			// nextState.items.push(item)
			nextState = addItem(item, nextState)
			nextState.updatedAt = momentNow
			return nextState
		case 'CLEAR_BASKET':
			nextState.restaurantId = null
			nextState.totalPrice = null
			nextState.restaurantName = ''
			nextState.items = []
			nextState.mode = 'onSite'
			nextState.tableName = ''
			nextState.comment = null
			nextState.updatedAt = null
			nextState.basketResult = {}
			nextState.order = null
			nextState.totalPrice = 0
			nextState.maxPreparationTime = 0
			nextState.info = null
			return nextState
		case 'CHANGE_MODE':
			nextState.mode = action.values.mode
			nextState.tableName = action.values.tableName
			// if (action.values.tableName) {
			// 	nextState.tableName = action.values.tableName;
			// 	nextState.updatedAt = nowString;
			// }
			if (action.values.comment !== undefined) {
				nextState.comment = action.values.comment
			}
			nextState.updatedAt = momentNow
			return nextState
		case 'CHANGE_PAYMENT':
			nextState.payment = action.values.payment
			nextState.updatedAt = momentNow
			return nextState
		case 'SET_TOTAL_PRICE':
			//			console.warn("action.values.totalPrice", action.values.totalPrice);
			nextState.totalPrice = parseFloat(action.values.totalPrice)
			// nextState.updatedAt = momentNow
			return nextState
		case 'CHANGE_REQUESTED_TIME':
			nextState.requestedTime = action.values.requestedTime
			nextState.updatedAt = momentNow
			return nextState
		case 'CHANGE_QUANTITY':
			for (var i = 0; i < nextState.items.length; i++) {
				if (nextState.items[i].timestamp == action.values.timestamp) {
					if (action.values.quantity == 0) {
						nextState.items.splice(i, 1)
					} else {
						nextState.items[i].quantity = action.values.quantity
					}
				}
			}
			nextState.updatedAt = momentNow
			return nextState
		case 'SAVE_BASKET_INFO':
			nextState.basketResult = action.values.result
			nextState.totalPrice = parseFloat(action.values.totalPrice)
			nextState.maxPreparationTime = parseFloat(action.values.maxPreparationTime)
			return nextState
		case 'SAVE_ORDER':
			nextState.order = action.values.order
			for (var i = 0; i < nextState.items.length; i++) {
				let item = { ...nextState.items[i] }

				let orderItem = null
				if (item.menuId) {
					orderItem = action.values.order.orderMenus.find((elt) => elt.timestamp == item.timestamp)
				} else {
					orderItem = action.values.order.orderItems.find((elt) => elt.timestamp == item.timestamp)
				}
				if (orderItem) {
					item.status = orderItem.status
					item.itemId = orderItem.orderItemId || orderItem.orderMenuId
				}

				nextState.items[i] = item
			}
			return nextState
		case 'SELECT_BIPER':
			nextState.biper = action.values.biper
			return nextState
		case 'SAVE_DISCOUNT':
			nextState.discountAmount = parseFloat(action.values.discountAmount)
			nextState.fidelityAmount = parseFloat(action.values.fidelityAmount)
			nextState.isFree = false
			if (nextState.totalPrice == nextState.discountAmount) {
				nextState.isFree = true
			}
			return nextState
		case 'SAVE_INFO_ORDER':
			nextState.info = action.values.info
			return nextState
		case 'UPDATE_DEVICE_TOKEN':
			nextState.deviceToken = action.values.deviceToken
			return nextState
		default:
			return state
	}
}

const addItem = (item, nextState) => {
	let existingIndex = -1
	if (item.timestamp) {
		existingIndex = nextState.items.findIndex((it) => it.timestamp == item.timestamp)
	}

	if (existingIndex !== -1) {
		nextState.items[existingIndex] = item
	} else {
		item.timestamp = item.timestamp || Date.now()
		// nextState.items.push(item);

		existingIndex = nextState.items.findIndex((it) => it.type == 'product' && item.type == 'product' && item.productId == it.productId)
		if (existingIndex !== -1) {
			nextState.items[existingIndex].quantity += item.quantity
		} else {
			nextState.items.push(item)
		}
	}
	return nextState
}

export default basket
