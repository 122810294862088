import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Home from "@screens/home/home";
import Restaurant from "@screens/restaurant/restaurant";
import Menu from "@screens/menu/menu";
import Product from "@screens/product/product.js";
import Basket from "@screens/orders/basket";
import PaymentChoice from "@screens/orders/PaymentChoice";
import PayWithCard from "@screens/orders/payWithCard.js";
import OrderNum from "@screens/orders/orderNum.js";
import Thankyou from "@screens/orders/thankyou.js";
import OrderFollow from "@screens/orderFollow/orderFollow.js";

import { Store } from "../../store/configureStore";
import verifyPayment from "../../screens/orders/VerifyPayment";

const Stack = createStackNavigator();

export default function RootStack() {
	const getPrefixTitle = () => {
		if (
			Store.getState() &&
			Store.getState().appStore &&
			Store.getState().appStore.restaurant.name
		) {
			return Store.getState().appStore.restaurant.name + " | ";
		} else {
			return "";
		}
	};
	return (
		<Stack.Navigator
			initialRouteName="Home"
			screenOptions={{ gestureEnabled: false, title: null, headerShown: false }}
		>
			<Stack.Screen
				name="HomeScreen"
				component={Home}
				options={{ title: getPrefixTitle() + "Accueil" }}
			/>
			<Stack.Screen
				name="RestaurantScreen"
				component={Restaurant}
				options={{ title: getPrefixTitle() + "Faites votre choix" }}
			/>
			<Stack.Screen
				name="MenuScreen"
				component={Menu}
				options={({ route }) => ({
					title: getPrefixTitle() + route.params.title,
				})}
			/>
			{/* <Stack.Screen
				name='ProductScreen'
				component={Product}
				options={({ route }) => ({ title: route.params.title })}
			/> */}
			<Stack.Screen
				name="BasketScreen"
				component={Basket}
				options={{ title: getPrefixTitle() + "Panier" }}
			/>
			<Stack.Screen
				name="PaymentChoiceScreen"
				component={PaymentChoice}
				options={{ title: getPrefixTitle() + "Choix du paiement" }}
			/>
			<Stack.Screen
				name="PayWithCardScreen"
				component={PayWithCard}
				options={{ title: "Accueil" }}
			/>
			<Stack.Screen
				name="VerifyPaymentScreen"
				component={verifyPayment}
				options={{ title: getPrefixTitle() + "" }}
			/>
			{/* <Stack.Screen
				name='OrderNumScreen'
				component={OrderNum}
				options={{ title: getPrefixTitle() + "" }}
			/> */}
			<Stack.Screen
				name="ThankyouScreen"
				component={Thankyou}
				options={{ title: getPrefixTitle() + "Merci pour votre commande" }}
			/>
			<Stack.Screen
				name="OrderFollowScreen"
				component={OrderFollow}
				options={{ title: getPrefixTitle() + "Suivre ma commande" }}
			/>
		</Stack.Navigator>
	);
}
