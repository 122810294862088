const initialState = { login: false, token: null };

function auth(state = initialState, action) {
	let nextState = { ...state };
	switch (action.type) {
		case "LOGIN":
			nextState.login = action.value.login;
			nextState.token = action.value.token;
			nextState.user = action.value.user;
			return nextState;
		case "LOGOUT":
			nextState.login = false;
			nextState.token = null;
			nextState.user = null;
			return nextState;
		case "UPDATE_USER":
			nextState.user = action.value.user;
			return nextState;
		default:
			return state;
	}
}

export default auth;
