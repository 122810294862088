import React from "react";
import { Alert } from "react-native";
import styled from "styled-components/native";
import { Center } from "@utils/styled-components";
import Loader from "@components/Loader";
import { post } from "@utils/api";
import { connect } from "react-redux";
import SecureView from "../user/SecureView";
import withRouter from "@utils/withRouter";
import dayjs from "dayjs";

const Main = styled.View`
	display: flex;
	padding: 20px;
	align-items: flex-start;
	justify-content: space-between;
	height: 100%;
	flex: 1;
`;
const BottomRow = styled.View`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0 10px 0;
`;

class PayWithCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			totalPrice: parseFloat(this.props.basketStore.totalPrice),
			secureUrl: null,
			isSubmitting: false,
		};
	}

	static navigationOptions = {
		headerShown: false,
	};
	componentDidMount() {
		this.makePayment();
	}

	makePayment() {
		// first gets info of user, then makes a Payin to the user's wallet, then a transfert to the restaurant's wallet
		console.log("card payment");
		let paymentData = {
			// debitedFunds: this.state.totalPrice,
			// fees: this.state.totalPrice * (1.8 / 100) + 0.1,
			orderId: this.props.basketStore.order.orderId,
		};
		console.warn("paymentData", paymentData);
		this.payinWithMangopay(paymentData);
	}

	payinWithMangopay(paymentData) {
		// makes the payin from user CC to user's wallet
		post(
			"/webPayInMangopay",
			paymentData,
			(res) => {
				console.warn("payinWithMangopay ok", res.paymentResult.RedirectURL);
				window.location = res.paymentResult.RedirectURL;
				// this.setState({
				// 	secureUrl: res.paymentResult.RedirectURL,
				// })
			},
			(err) => {
				console.warn("payinWithMangopay err", err);
				err.json().then((json) => {
					// json here is the error data
					// if (json.paymentResult && json.paymentResult.Status === 'FAILED') {
					Alert.alert(
						"Une erreur est survenue",
						json.paymentResult.ResultMessage +
							"\nCode d'erreur: " +
							json.paymentResult.ResultCode +
							"\nLe paiement n'a pas été effectué. \nVeuillez réessayer plus tard ou contacter le support.",
						[
							{
								text: "Réessayer",
								onPress: () => this.makePayment(),
							},
						],
						{ cancelable: false }
					);
					// }
				});
			}
		);
	}

	validateOrder = () => {
		this.setState({
			loading: true,
		});

		post(
			"/order",
			{
				...this.props.basketStore,
				...this.props.basketStore.items,
				totalPrice: parseFloat(this.props.basketStore.totalPrice),
				restaurantId: this.props.appStore.restaurant.restaurantId,
				appId: this.props.appStore.app.appId,
				payment: "CARD",
				orderId: this.props.basketStore.order.orderId,
				requestedTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
				orderTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
				status: "done",
				kitchenStatus: "toPrepare",
			},
			async (res) => {
				let action = {
					type: "SAVE_ORDER",
					values: {
						order: res.order,
					},
				};

				this.props.dispatch(action);
				this.setState({
					loading: false,
				});

				this.props.navigation.navigate("ThankyouScreen", {
					orderId: this.props.basketStore.order.orderId,
					appId: this.props.appStore.app.appId,
				});

				// let printed = await this.print(res.order)
				// if (printed) {
				// 	post(
				// 		'/printOrderTicket/' + res.order.orderId,
				// 		{
				// 			ticketType: 'ticket',
				// 			tickets: [
				// 				{
				// 					ticketType: 'ticket',
				// 					appType: 'borne',
				// 				},
				// 			],
				// 		},
				// 		(res) => {
				// 			console.log('printOrderTicket success')
				// 		},
				// 		(err) => {
				// 			console.error('printOrderTicket error', err)
				// 		}
				// 	)
				// }
			},
			(err) => {
				console.warn("err in posting the order", err);
				this.setState({
					loading: false,
				});
			}
		);
	};

	render() {
		const colors = this.props.appStore.restaurant.style;
		if (this.state.secureUrl && !this.state.loading) {
			return (
				<SecureView
					url={this.state.secureUrl}
					orderId={this.props.basketStore.order.orderId}
					closeSecureView={(success) => {
						this.setState(
							{
								secureUrl: null,
								paymentSuccess: success,
							},
							() => {
								if (success) {
									this.validateOrder();
								} else {
									this.props.navigation.navigate("BasketScreen", {
										appId: this.props.appStore.app.appId,
									});
								}
							}
						);
					}}
				/>
			);
		} else {
			return (
				<Center>
					<Loader name={"circle"} color={colors.secondary} />
				</Center>
			);
		}
	}
}
const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(PayWithCard));
