import React from "react";
import { StyleSheet, Image, TouchableOpacity, Text } from "react-native";
import { connect } from "react-redux";
import { get } from "@utils/api";
import { Center } from "@utils/styled-components";
// import { withRouter } from "react-navigation";
import { BASE_URL } from "@env";
import Loader from "@components/Loader";
import defaultColors from "@utils/colors";
import withRouter from "@utils/withRouter";

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
		};
	}

	static navigationOptions = {
		headerShown: false,
	};

	componentDidMount() {
		let url = new URL(window.location);
		let params = new URLSearchParams(url.search);
		console.log("home componentDidmount", params.get("orderFollow"));
		if (params.get("orderFollow")) {
			console.log("home componentDidmount", "orderFollow");
			this.props.navigation.navigate("OrderFollowScreen", {
				orderId: params.get("orderFollow"),
				appId: params.get("appId"),
			});
		} else {
			console.log(url.search, params.get("appId"));
			this.getAppInfo(params.get("appId"));
		}
	}

	getAppInfo = (appId) => {
		this.setState({ loading: true });
		get(
			"/appInfo/" + appId,
			(res) => {
				// this.props.dispatch({
				// 	type: 'SAVE_DATA',
				// 	values: {
				// 		app: res.application,
				// 	},
				// })

				let restaurant = res.restaurants[0];

				restaurant.style = restaurant.style
					? JSON.parse("{" + restaurant.style.replace(/'/g, '"') + "}")
					: {};
				restaurant.style = { ...defaultColors, ...restaurant.style };

				this.props.navigation.setOptions({ title: restaurant.name });

				// console.log('style', this.props.appStore.restaurant.style)
				this.setState({
					app: res.application,
					restaurant,
					isReady: true,
					loading: false,
				});
				this.props.dispatch({
					type: "SAVE_DATA",
					values: {
						app: res.application,
						restaurant,
					},
				});

				this.setState({ app: res.application });
				//this.getRestaurantInfos(res.application.appId)
			},
			(err) => {
				console.warn(err.status);
			}
		);
	};

	clearCart() {
		let action = {
			type: "CLEAR_BASKET",
		};
		this.props.dispatch(action);

		this.props.navigation.navigate("RestaurantScreen", {
			appId: this.props.appStore.app.appId,
		});
		// this.props.navigation.navigate('OrderFollowScreen', { orderId: 66203 })
	}

	render() {
		//		const colors = this.props.appStore.restaurant.style;
		return this.state.loading ? (
			<Center>
				<Loader name={"circle"} color={defaultColors.secondary} />
			</Center>
		) : (
			<TouchableOpacity
				style={styles.container}
				onPress={() => {
					this.clearCart();
				}}
			>
				{this.state.app.backgroundImage ? (
					<Image
						style={styles.img}
						source={{
							uri:
								"https://res.cloudinary.com/drive-eat/image/fetch/w_1000/" +
								BASE_URL +
								this.state.app.backgroundImage,
						}}
					/>
				) : (
					<Image
						style={styles.image}
						source={require("@assets/imageBackground.jpg")}
					/>
				)}
				<TouchableOpacity
					style={[
						styles.button,
						{ backgroundColor: this.state.restaurant.style.bg },
					]}
					onPress={() => {
						this.clearCart();
					}}
				>
					<Text style={styles.text}>TOUCHER L'ECRAN POUR COMMANDER</Text>
				</TouchableOpacity>
			</TouchableOpacity>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "flex-end",
	},
	img: {
		flex: 1,
		width: "100%",
		height: "88%",
		resizeMode: "cover",
	},
	button: {
		display: "flex",
		alignSelf: "flex-end",
		alignItems: "center",
		justifyContent: "center",
		height: "12%",
		width: "100%",
	},
	text: {
		color: "#FFF",
		fontSize: 20,
		fontWeight: "bold",
		textAlign: "center",
	},
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(Home));
