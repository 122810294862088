import React from "react";
import {
	StyleSheet,
	View,
	Image,
	TouchableOpacity,
	Dimensions,
	ScrollView,
} from "react-native";
import { StyledButton, Center, Tex, LTex } from "@utils/styled-components";
import { connect } from "react-redux";
import styled from "styled-components/native";
import Loader from "@components/Loader";
import { MdReplyAll } from "react-icons/md";
import withRouter from "@utils/withRouter";

const { height, width } = Dimensions.get("window");
const IMAGEHEIGHT = height * 0.58;

const TitleSection = styled.View`
	padding: 18px 20px;
	position: relative;
	width: 100%;
	justify-content: flex-start;
	text-align: center;
`;
const PartSection = styled.View`
	background-color: #fff;
	padding: 10px 20px;
	margin-vertical: 2px;
`;
const MenuName = styled(LTex)`
	color: #40403f;
	font-size: 20px;
	font-weight: 700;
	line-height: 35px;
`;
const MenuDesc = styled(LTex)`
	color: #40403f;
	font-size: 14px;
	font-weight: 300;
	line-height: 26px;
`;
const PartTitle = styled(LTex)`
	color: #3d3e43;
	font-size: 16px;
	line-height: 29px;
	text-align: center;
`;
const PartMandatory = styled(LTex)`
	color: #9da1a2;
	font-size: 14px;
	line-height: 23px;
	text-align: center;
`;
const ProductDiv = styled.TouchableOpacity`
	position: relative;
	width: 40%;
	height: 130px;
	margin: 5px;
	padding: 4px;
	border-width: ${(props) => (props.selected ? "3px" : "0px")};
	border-color: ${(props) => (props.selected ? "#ed1c24" : "#FFF")};
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	opacity: ${(props) => (props.disabled ? "0.5" : "1")};
	elevation: 3;
	background-color: #fff;
`;
const ProductWrapper = styled.View`
	display: flex;
	z-index: 555;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: space-around;
	opacity: ${(props) => (props.disabled ? "0.8" : "1")};
`;
const Indisponible = styled(LTex)`
	color: #ffffff;
	font-size: 13px;
	font-weight: bold;
	line-height: 14px;
	text-align: center;
`;
const IndisponibleArea = styled.View`
	border-radius: 5px;
	background-color: #9da1a2;
	width: 85%;
	padding: 5px 5px;
`;
const MyLTex = styled(LTex)`
	width: 100%;
	font-size: 13px;
	line-height: 14px;
	text-align: center;
	font-weight: bold;
	color: #40403f;
	z-index: 999;
`;
const GreyInterstice = styled.View`
	height: 1px;
	width 100%;
	background-color: #EFEFEF;
`;
const NumberCircle = styled.View`
	height: 22px;
	width: 60px;
	border-radius: 7px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 5px;
	background-color: #ed1c24;
`;
const QuantityCircle = styled.View`
	height: 25px;
	width: 25px;
	border-width: 1px;
	border-color: #ddd;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
`;
const TopRow = styled.View`
	position: absolute;
	top: 15px;
	display: flex;
	justify-content: space-between;
	padding: 15px;
	width: 100%;
	z-index: 2;
	flex-direction: row;
`;
const Row = styled.View`
	display: flex;
	flex-direction: row;
	padding-horizontal: 10px;
	width: 100%;
	height: 30px;
	justify-content: space-around;
	align-items: center;
`;

class Menu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			selectedCategory: "Test",
			categories: [],
			selectedItems: {},
			quantity: 1,
			totalPrice: 0,
			isModalVisible: false,
			step: 1,
		};
		// this.scrollView = null
	}

	static navigationOptions = {
		headerShown: false,
	};

	canAddToCart() {
		// user can only add to cart when he selected at least all the mandatory sections, with the right number of items
		let bool = true;
		this.state.menu.menuParts.map((menuPart, index) => {
			const prevMP = index > 0 && this.state.menu.menuParts[index - 1];
			if (
				(menuPart.mandatory && !menuPart.linkPreviousPart) ||
				(menuPart.linkPreviousPart &&
					prevMP &&
					this.state.selectedItems[prevMP.menuPartId] &&
					this.state.selectedItems[prevMP.menuPartId].length)
			) {
				let nbItemsInPart = this.getNbItemsInPart(
					this.state.selectedItems[menuPart.menuPartId]
				);
				if (nbItemsInPart < menuPart.itemsAllowed) {
					bool = false;
				}
			}
		});
		return bool;
	}

	canGoNext() {
		let bool = true;
		let currentStep = this.state.step;
		let menuParts = this.state.menu.menuParts.filter((elt, ind) => {
			const prevMP = ind > 0 && this.state.menu.menuParts[ind - 1];
			return (
				((elt.products.length > 1 || !elt.mandatory) &&
					!elt.linkPreviousPart) ||
				(elt.linkPreviousPart &&
					prevMP &&
					this.state.selectedItems[prevMP.menuPartId] &&
					this.state.selectedItems[prevMP.menuPartId].length)
			);
		});

		let menuPart = menuParts[currentStep - 1];

		if (menuPart && menuPart.mandatory) {
			let nbItemsInPart = this.getNbItemsInPart(
				this.state.selectedItems[menuPart.menuPartId]
			);
			if (nbItemsInPart < menuPart.itemsAllowed) {
				bool = false;
			}
		}
		return bool;
	}

	hasItemSelected() {
		let currentStep = this.state.step;
		let menuParts = this.state.menu.menuParts.filter(
			(elt) => !elt.mandatory || elt.products.length > 1
		);

		let menuPart = menuParts[currentStep - 1];
		return this.getNbItemsInPart(this.state.selectedItems[menuPart.menuPartId]);
	}

	getMenuDetails() {
		let timestamp = this.props.route.params.timestamp;
		let menuId = this.props.route.params.menuId;

		// get(
		//   '/fullMenuInfos/' + this.props.navigation.getParam('menuId', 45),
		//   (res) => {
		let menu = this.props.appStore.menusWithCategories.find(
			(elt) => elt.menuId == menuId
		);
		let selectedItems = {};
		let selectedProductCategories = {};

		let menuPrice = parseFloat(menu.price);
		let quantity = this.state.quantity;
		if (timestamp) {
			let existingItem = this.props.basketStore.items.find(
				(elt) => elt.timestamp == timestamp
			);
			selectedItems = existingItem.content;
			quantity = existingItem.quantity;
			menuPrice = parseFloat(existingItem.price);
			menu.itemId = existingItem.itemId;
		}

		for (let i = 0; i < menu.menuParts.length; i++) {
			let menuPart = menu.menuParts[i];
			if (
				menuPart.mandatory &&
				menuPart.products.length === menuPart.itemsAllowed &&
				menuPart.products.filter((prod) => prod.disabled).length < 1
			) {
				let arr = [];
				for (let prod of menuPart.products) {
					arr.push({ ...prod });
					//selectedProductCategories.push(prod.categoryName);
				}
				selectedItems[menuPart.menuPartId] = arr;
			}
		}
		this.setState({
			menu,
			isReady: true,
			selectedItems,
			selectedProductCategories,
			totalPrice: menuPrice.toFixed(2),
			timestamp,
		});
		//   },
		//   () => {
		//     //console.error(err.status);
		//   },
		// );
	}

	addToCart(selectedItems) {
		if (!this._isAddingToCart) {
			this._isAddingToCart = true;
			if (parseFloat(this.state.totalPrice) > 0) {
				this.addItemToCart(selectedItems || this.state.selectedItems);
			}
			this.props.navigation.goBack();
		}
	}

	addItemToCart(selectedItems) {
		let item = { ...this.state.menu };
		item.quantity = this.state.quantity;
		item.menuId = this.state.menu.menuId;
		item.price = this.state.totalPrice;
		item.menuPrice = this.state.menu.price;
		item.content = selectedItems;
		item.menuPrice = this.state.menu.price;

		item.selectedItems = Object.values(selectedItems).reduce((acc, item) => {
			acc.push(...item);
			return acc;
		}, []);

		item.name = this.state.menu.name;
		item.timestamp = this.state.timestamp;
		item.type = "menu";
		let action = {
			type: "ADD_TO_BASKET",
			values: {
				restaurantId: this.state.menu.restaurantId,
				item,
			},
		};
		this.props.dispatch(action);
	}

	componentDidMount() {
		this.getMenuDetails();
		this._isAddingToCart = false;
	}

	previousStep() {
		let currentStep = this.state.step;
		currentStep--;
		this.setState({ step: currentStep, slideEffect: "zoomIn" });
	}

	nextStep() {
		let currentStep = this.state.step;

		let foundIndex = this.state.menu.menuParts.findIndex(
			(elt) => elt.menuPartId == this.currentMenuPartId
		);

		let nextIndex = foundIndex + 1;
		let nextMenuPart = this.state.menu.menuParts[nextIndex];
		if (
			foundIndex !== -1 &&
			nextMenuPart &&
			nextMenuPart.linkPreviousPart &&
			this.state.selectedItems[nextMenuPart.menuPartId] &&
			(!this.state.selectedItems[this.currentMenuPartId] ||
				!this.state.selectedItems[this.currentMenuPartId].length)
		) {
			this.state.selectedItems[nextMenuPart.menuPartId] = [];
		}

		currentStep++;
		this.setState({ step: currentStep, slideEffect: "zoomIn" });
	}

	onSelected = (menuPart, product) => {
		let selectedItems = {
			...this.state.selectedItems,
		};
		let max =
			menuPart.itemsAllowed && !menuPart.supplementAfterMaxAllowed
				? menuPart.itemsAllowed
				: 0;
		let newPrice = parseFloat(this.state.totalPrice);

		let array = selectedItems[menuPart.menuPartId] || [];
		product.newPrice = parseFloat(product.price);
		let goNext = false;

		let uniqueProduct = menuPart.products.find((elt) => elt.uniqueInPart);
		let hasUniqueSelected =
			uniqueProduct && array.indexOf(uniqueProduct.menuPartItemId) !== -1;

		const foundIndex = array.findIndex(
			(elt) => elt.menuPartItemId == product.menuPartItemId
		);

		let nbItemsInPart = this.getNbItemsInPart(array);

		product.quantity = 1;

		if (foundIndex > -1) {
			array.splice(foundIndex, 1);
		} else if (product.uniqueInPart) {
			array = [{ ...product }];
			goNext = true;
		} else if (max && nbItemsInPart == max) {
			array[0] = { ...product };
		} else {
			if (hasUniqueSelected) {
				array.splice(array.indexOf(uniqueProduct.menuPartItemId), 1);
			}

			array.push({ ...product, order: menuPart.menuPartOrder });
		}

		selectedItems[menuPart.menuPartId] = [...array];

		nbItemsInPart = this.getNbItemsInPart(array);
		const totalPrice = this.getMenuTotalPrice(selectedItems);

		this.setState(
			{
				selectedItems,
				totalPrice,
			},
			() => {
				if (
					(max &&
						nbItemsInPart == max &&
						!menuPart.supplementAfterMaxAllowed) ||
					goNext
				) {
					if (this.state.step < this.maxSteps) {
						this.nextStep();
					} else if (this.state.step == this.maxSteps) {
						this.addToCart(selectedItems);
					}
				}
			}
		);
	};

	addQuantity = (menuPart, product) => {
		let max =
			menuPart.itemsAllowed && !menuPart.supplementAfterMaxAllowed
				? menuPart.itemsAllowed
				: 0;
		let selectedItems = this.state.selectedItems;
		let nbItemsInPart = this.getNbItemsInPart(
			selectedItems[menuPart.menuPartId]
		);

		if (max && nbItemsInPart == max && !menuPart.supplementAfterMaxAllowed) {
			return;
		}

		let foundIndex = selectedItems[menuPart.menuPartId].findIndex(
			(elt) => elt.menuPartItemId == product.menuPartItemId
		);
		selectedItems[menuPart.menuPartId][foundIndex].quantity += 1;

		nbItemsInPart = this.getNbItemsInPart(selectedItems[menuPart.menuPartId]);

		const totalPrice = this.getMenuTotalPrice(selectedItems);

		this.setState(
			{
				selectedItems,
				totalPrice,
			},
			() => {
				if (
					max &&
					nbItemsInPart == max &&
					!menuPart.supplementAfterMaxAllowed
				) {
					if (this.state.step < this.maxSteps) {
						this.nextStep();
					} else if (this.state.step == this.maxSteps) {
						this.addToCart(selectedItems);
					}
				}
			}
		);
	};

	removeQuantity = (menuPart, product) => {
		let selectedItems = this.state.selectedItems;
		let foundIndex = selectedItems[menuPart.menuPartId].findIndex(
			(elt) => elt.menuPartItemId == product.menuPartItemId
		);
		selectedItems[menuPart.menuPartId][foundIndex].quantity -= 1;

		if (selectedItems[menuPart.menuPartId][foundIndex].quantity == 0) {
			selectedItems[menuPart.menuPartId].splice(foundIndex, 1);
		}

		const totalPrice = this.getMenuTotalPrice(selectedItems);

		this.setState({
			selectedItems,
			totalPrice,
		});
	};

	getNbItemsInPart = (menuPartItems) => {
		return menuPartItems
			? menuPartItems.reduce((acc, curr) => (acc += curr.quantity), 0)
			: 0;
	};

	getMenuTotalPrice = (menuParts) => {
		let totalPrice = parseFloat(this.state.menu.price);

		for (const [menuPartId, menuPartItems] of Object.entries(menuParts)) {
			const menuPart = this.state.menu.menuParts.find(
				(elt) => elt.menuPartId == menuPartId
			);

			let nbItemsNoSupplementSelected = 0;
			menuPartItems
				.sort((a, b) => (a.price > b.price ? 1 : b.price > a.price ? -1 : 0))
				.forEach((product, index) => {
					if (product.supplement) {
						totalPrice += parseFloat(product.price) * product.quantity;
					} else {
						product.supplementAfterMax = 0;
						product.quantitySupplement = 0;
						for (let q = 0; q < product.quantity; q++) {
							nbItemsNoSupplementSelected += 1;
							if (
								menuPart.supplementAfterMaxAllowed &&
								nbItemsNoSupplementSelected > menuPart.itemsAllowed
							) {
								totalPrice += parseFloat(product.price);
								product.supplementAfterMax = 1;
								product.quantitySupplement += 1;
								product.itemsAllowed = menuPart.itemsAllowed;
							}
						}
					}
				});
		}

		console.log({ totalPrice });
		return totalPrice;
	};

	removeProductsFromMenuPart = (menuPart) => {
		let selectedItems = this.state.selectedItems;
		selectedItems[menuPart.menuPartId] = [];
		const totalPrice = this.getMenuTotalPrice(selectedItems);
		this.setState({
			selectedItems,
			totalPrice,
		});
	};

	render() {
		const colors = this.props.appStore.restaurant.style;
		if (!this.state.isReady)
			return (
				<Center>
					<Loader name={"circle"} color={colors.secondary} />
				</Center>
			);
		let stepIndex = 0;
		this.maxSteps = 0;
		return (
			<View style={styles.container}>
				<TopRow>
					<TouchableOpacity
						style={styles.icon}
						onPress={() => {
							this.props.navigation.goBack();
						}}
					>
						<MdReplyAll color={colors.secondary} size={24} />
					</TouchableOpacity>
				</TopRow>
				<ScrollView
					style={{ position: "relative", backgroundColor: "#EBEBEB" }}
					showsVerticalScrollIndicator={false}
					ref={(ref) => (this.scrollView = ref)}
				>
					<View style={styles.imageContainer}>
						<Image
							source={{
								uri:
									"http://res.cloudinary.com/drive-eat/image/fetch/h_1500/" +
									this.state.menu.image,
							}}
							style={{ width: "100%", height: IMAGEHEIGHT, zIndex: 1 }}
							resizeMode="cover"
						/>
					</View>
					<View style={styles.productContainer}>
						<TitleSection>
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<MenuName style={{ color: colors.primary }}>
									{this.state.menu.name}
								</MenuName>
								<MenuName style={{ color: colors.primary }}>
									{parseFloat(this.state.totalPrice).toFixed(2)} €
								</MenuName>
							</View>
							<MenuDesc>{this.state.menu.description}</MenuDesc>
						</TitleSection>
					</View>
					{this.state.menu.menuParts.map((menuPart, indexMP) => {
						const lastMP =
							indexMP > 0 && this.state.menu.menuParts[indexMP - 1];
						const nbItemsInPart = this.getNbItemsInPart(
							this.state.selectedItems[menuPart.menuPartId]
						);
						if (menuPart.mandatory && menuPart.products.length == 1) {
						} else if (
							lastMP &&
							(!this.state.selectedItems[lastMP.menuPartId] ||
								!this.state.selectedItems[lastMP.menuPartId].length) &&
							menuPart.linkPreviousPart
						) {
						} else {
							stepIndex++;
							this.maxSteps++;
							if (this.state.step == stepIndex) {
								this.currentMenuPartId = menuPart.menuPartId;
								return (
									<React.Fragment key={menuPart.menuPartId}>
										<PartSection>
											<PartTitle style={{ color: colors.primary }} bold>
												{menuPart.name}
											</PartTitle>
											{menuPart.mandatory ? (
												<PartMandatory>
													Obligatoire
													{menuPart.products.length > 1 &&
														" - " + menuPart.itemsAllowed + " à choisir"}
												</PartMandatory>
											) : (
												<PartMandatory>
													{menuPart.itemsAllowed == "1" &&
														menuPart.itemsAllowed + " au choix"}
												</PartMandatory>
											)}
										</PartSection>
										<View style={styles.content}>
											{menuPart.products.map((product) => {
												let isSelected =
													this.state.selectedItems[menuPart.menuPartId] &&
													this.state.selectedItems[menuPart.menuPartId].find(
														(elt) =>
															elt.menuPartItemId == product.menuPartItemId
													);
												const showPrice =
													product.price &&
													(!isSelected ||
														isSelected.supplementAfterMax ||
														isSelected.supplement);
												return product.disabled ? (
													<ProductDiv
														key={product.menuPartItemId}
														ref={product.menuPartItemId}
														style={styles.shadow}
													>
														<ProductWrapper disabled={product.disabled}>
															<MyLTex
																color="#B0B1B4"
																marg="0 0 0 10px"
																numberOfLines={2}
															>
																{product.name}
															</MyLTex>
															<IndisponibleArea>
																<Indisponible>INDISPONIBLE</Indisponible>
															</IndisponibleArea>
															{product.image && (
																<Image
																	style={styles.img}
																	source={{
																		uri:
																			"http://res.cloudinary.com/drive-eat/image/fetch/w_150/" +
																			product.image,
																	}}
																/>
															)}
														</ProductWrapper>
													</ProductDiv>
												) : (
													<ProductDiv
														key={product.menuPartItemId}
														ref={product.menuPartItemId}
														style={styles.shadow}
														selected={isSelected}
														onPress={() => {
															this.onSelected(menuPart, product);
														}}
													>
														<MyLTex
															color="#3D3E43"
															marg="0 0 0 0px"
															numberOfLines={2}
														>
															{product.name}
														</MyLTex>
														{product.composition ? (
															<LTex
																marg="0px 10px"
																style={styles.desc}
																numberOfLines={2}
															>
																{product.composition}
															</LTex>
														) : null}
														{product.image && (
															<Image
																style={styles.img}
																source={{
																	uri:
																		"http://res.cloudinary.com/drive-eat/image/fetch/w_150/" +
																		product.image,
																}}
															/>
														)}
														{product.price > 0 &&
														(product.supplement ||
															(menuPart.supplementAfterMaxAllowed &&
																nbItemsInPart >= menuPart.itemsAllowed)) &&
														showPrice ? (
															<NumberCircle
																style={{ backgroundColor: colors.secondary }}
															>
																<Tex style={styles.price}>
																	{parseFloat(product.price) > 0
																		? "+ " +
																		  parseFloat(product.price).toFixed(2) +
																		  " €"
																		: ""}
																</Tex>
															</NumberCircle>
														) : null}
														{isSelected &&
														menuPart.enableQuantity &&
														(menuPart.itemsAllowed > 1 ||
															(menuPart.itemsAllowed == 1 &&
																menuPart.supplementAfterMaxAllowed)) ? (
															<Row>
																<TouchableOpacity
																	style={styles.quantity}
																	onPress={() => {
																		this.removeQuantity(menuPart, product);
																	}}
																>
																	<QuantityCircle>
																		<MyLTex>-</MyLTex>
																	</QuantityCircle>
																</TouchableOpacity>
																<MyLTex>
																	{
																		this.state.selectedItems[
																			menuPart.menuPartId
																		].find(
																			(p) =>
																				p.menuPartItemId ==
																				product.menuPartItemId
																		).quantity
																	}
																</MyLTex>
																<TouchableOpacity
																	style={styles.quantity}
																	onPress={() => {
																		this.addQuantity(menuPart, product);
																	}}
																>
																	<QuantityCircle>
																		<MyLTex>+</MyLTex>
																	</QuantityCircle>
																</TouchableOpacity>
															</Row>
														) : null}
													</ProductDiv>
												);
											})}
										</View>
									</React.Fragment>
								);
							} else {
								return null;
							}
						}
					})}
					<GreyInterstice />
					<View style={styles.wrapper}>
						{this.state.step > 1 ? (
							<StyledButton
								colors={colors}
								width="40%"
								onPress={() => {
									this.scrollView.scrollTo({ y: IMAGEHEIGHT });
									this.previousStep();
								}}
							>
								<Tex color="#FFF" bold>
									PRECEDENT
								</Tex>
							</StyledButton>
						) : null}
						{this.state.step < this.maxSteps ? (
							<StyledButton
								colors={colors}
								width="40%"
								disabled={!this.canGoNext()}
								onPress={() => {
									this.scrollView.scrollTo({ y: IMAGEHEIGHT });
									this.nextStep();
								}}
							>
								{this.state.step < this.maxSteps ? (
									this.hasItemSelected() || !this.canGoNext() ? (
										<Tex color="#FFF" bold>
											CONTINUER
										</Tex>
									) : (
										<Tex color="#FFF" bold>
											NON MERCI
										</Tex>
									)
								) : null}
							</StyledButton>
						) : null}
						{this.state.step === this.maxSteps ? (
							<StyledButton
								colors={colors}
								width="40%"
								disabled={!this.canAddToCart()}
								onPress={() => {
									if (this.canAddToCart()) {
										this.addToCart();
									}
								}}
							>
								{!this.canAddToCart() || this.hasItemSelected() ? (
									<Tex color="#FFF" bold>
										VALIDER
									</Tex>
								) : (
									<Tex color="#FFF" bold>
										NON MERCI
									</Tex>
								)}
							</StyledButton>
						) : null}
					</View>
				</ScrollView>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: { flex: 1, backgroundColor: "#EBEBEB" },
	icon: {
		backgroundColor: "#FFF",
		borderRadius: 5,
		height: 33,
		width: 33,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	productContainer: {
		borderTopLeftRadius: 30,
		borderTopRightRadius: 30,
		backgroundColor: "#FFF",
		marginTop: -10,
	},
	shadow: {
		shadowColor: "#FFFFFF",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.12,
		shadowRadius: 1.22,

		elevation: 3,
		overflow: "hidden",
	},
	wrapper: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-evenly",
		padding: 5,
		backgroundColor: "#FFF",
	},
	img: {
		resizeMode: "contain",
		width: "65%",
		height: "40%",
		borderRadius: 10,
	},
	imageContainer: {
		position: "relative",
		marginTop: -1000,
		paddingTop: 1000,
		overflow: "hidden",
	},
	image: (scrollA) => ({
		flex: 1,
		height: IMAGEHEIGHT,
		opacity: scrollA.interpolate({
			inputRange: [-IMAGEHEIGHT, 0, IMAGEHEIGHT, IMAGEHEIGHT + 1],
			outputRange: [1, 1, 0.5, 0.5],
		}),
		transform: [
			{
				translateY: scrollA.interpolate({
					inputRange: [-IMAGEHEIGHT, 0, IMAGEHEIGHT, IMAGEHEIGHT + 1],
					outputRange: [
						-IMAGEHEIGHT / 2,
						0,
						IMAGEHEIGHT * 0.75,
						IMAGEHEIGHT * 0.75,
					],
				}),
			},
			{
				scale: scrollA.interpolate({
					inputRange: [-IMAGEHEIGHT, 0, IMAGEHEIGHT, IMAGEHEIGHT + 1],
					outputRange: [1, 1, 1, 0],
				}),
			},
		],
	}),
	content: {
		width: "100%",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "space-evenly",
		padding: 10,
	},
	desc: {
		textAlign: "center",
		color: "#000",
		fontSize: 12,
		lineHeight: 13,
	},
	price: {
		color: "#FFF",
		fontWeight: "bold",
		fontSize: 13,
		lineHeight: 13,
	},
});

const mapStateToProps = (state) => {
	return state;
};
export default connect(mapStateToProps)(withRouter(Menu));
