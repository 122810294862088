import React from "react";
import {
	useNavigation,
	useRoute,
	useIsFocused,
} from "@react-navigation/native";

const withRouter = (WrappedComponent) => (props) => {
	const navigation = useNavigation();
	const route = useRoute();
	const isFocused = useIsFocused();

	return (
		<WrappedComponent
			{...props}
			navigation={navigation}
			route={route}
			isFocused={isFocused}
			// etc...
		/>
	);
};

export default withRouter;
