/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

const linking = {
	prefixes: ["http://localhost:19006/"],
	config: {
		initialRouteName: "/",
		screens: {
			HomeScreen: { path: "" },
			RestaurantScreen: "restaurant",
			MenuScreen: "menu",
			BasketScreen: "panier",
			PaymentChoiceScreen: "choix-paiement",
			VerifyPaymentScreen: "check-paiement",
			ThankyouScreen: "validation-commande",
			OrderFollowScreen: "suivre-commande",
			// Root: {
			//   screens: {
			//     Reservations: {
			//       screens: {
			//         ReservationsScreen: 'one',
			//       },
			//     },
			//     Notifications: {
			//       screens: {
			//         NotificationsScreen: 'two',
			//       },
			//     },
			//   },
			// },
			// Modal: 'modal',
		},
	},
};

export default linking;
