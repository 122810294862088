import React from 'react'
import { View, TouchableWithoutFeedback } from 'react-native'
import styled from 'styled-components/native'
import { connect } from 'react-redux'
import { StyledButton, WhiteText, Tex } from '@utils/styled-components'

const Main = styled.View`
	display: flex;
	align-items: center;
	flex-direction: column;
	flex: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 9999;
`
const Content = styled.View`
	width: 90%;
	height: 35%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	position: absolute;
	top: 30%;
	border-radius: 20px;
`
const ModalContentText = styled.Text`
	color: #3d3e43;
	font-size: 14px;
	font-weight: 300;
	line-height: 15px;
	text-align: center;
`
const ButtonWrapper = styled.View`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 30px;
`
class SureModal extends React.Component {
	constructor(props) {
		super(props)
	}

	removeModal = (e) => {
		if (e.currentTarget === e.target) {
			this.props.onClose()
		}
	}

	render() {
		const colors = this.props.appStore.restaurant.style
		return (
			<Main ref='modal'>
				<TouchableWithoutFeedback onPress={(e) => this.removeModal(e)}>
					<View
						style={{
							flex: 1,
							backgroundColor: 'rgba(164,173,177,0.5)',
							width: '100%',
							height: '100%',
						}}
					/>
				</TouchableWithoutFeedback>

				<Content ref='content'>
					<ModalContentText>
						<Tex lineHeight='18px' bold>
							Êtes-vous sûr(e) ?{'\n'}
						</Tex>
						<Tex lineHeight='18px'>Vous pouvez modifier un menu en le sélectionnant !</Tex>
					</ModalContentText>
					<ButtonWrapper>
						<StyledButton
							width='40%'
							bgColor={'#818181'}
							onPress={() => {
								this.props.clearCart()
							}}
						>
							<WhiteText bold>Oui, je confirme !</WhiteText>
						</StyledButton>
						<StyledButton colors={colors} onPress={() => this.props.onBackdropPress()} width='40%'>
							<WhiteText bold>Retour</WhiteText>
						</StyledButton>
					</ButtonWrapper>
				</Content>
			</Main>
		)
	}
}

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps)(SureModal)
