import React from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import * as Animatable from "react-native-animatable";
import { connect } from "react-redux";
import styled from "styled-components/native";
import { ErrorMessage, Formik } from "formik";
import {
	StyledButton,
	Tex,
	LSubTitle,
	LTex,
	WhiteText,
} from "@utils/styled-components";
import BasketOrderItem from "@components/basketOrderItem.js";
import { BASE_URL } from "@env";
import { getTotalPrice } from "@utils/common.js";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import BasketSettingItem from "@components/basketSettingItem";
import Banniere from "@components/Banniere";
import SureModal from "@components/SureModal";
import { MdReplyAll } from "react-icons/md";
import withRouter from "@utils/withRouter";

const StyledTextInput = styled.TextInput`
	width: 100%;
	background-color: white;
	color: #3d3e43;
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	padding: 10px;
	text-align-vertical: top;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
`;
const RowSetting = styled.View`
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	padding: 20px 5px;
`;
const Row = styled.View`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: ${(props) => (props.left ? "flex-start" : "center")};
	background-color: #fff;
	padding: 0px 10px 10px 10px;
	border-radius: 0px;
	margin: 5px;
`;
const HeaderRow = styled.TouchableOpacity`
	flex-direction: row;
	height: 80px;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	padding: 20px;
	margin-top: 10px;
	background-color: #fff;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
`;
const Content = styled.View`
	background-color: #f7f7f7;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0 60px 0;
`;
const RedTex = styled(Tex)`
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	border-radius: 10px;
	padding: 10px 5px;
	width: 80%;
`;
const PriceRow = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 40px;
	align-items: center;
	padding: 0 10px;
`;
const PriceText = styled(LTex)`
	color: #3d3e43;
	font-size: 16px;
	line-height: 26px;
	font-weight: ${(props) => (props.bold ? "bold" : "300")};
`;
const BottomRow = styled.View`
	background-color: #f7f7f7;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 10px 0 10px 0;
`;
const StyledInput = styled.TextInput`
	background-color: white;
	margin: 7px 0px;
	border: ${(props) => (props.border ? props.border : "none")};
	width: ${(props) => (props.width ? props.width : "97%")};
	padding: 7px;
	height: 50px;
	border-radius: 10px;
`;
const InputWrapper = styled.View`
	position: relative;
	width: 100%;
	align-items: center;
	justify-content: center;
`;
const MandatoryText = styled.Text`
	position: absolute;
	right: 10;
	top: 25;
	color: ${(props) => (props.color ? props.color : "#b0b1b4")};
	font-size: 11px;
	line-height: 18px;
	text-align: right;
`;

class BasketScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			comment: "",
			name:
				(this.props.basketStore.info && this.props.basketStore.info.name) || "",
			email:
				(this.props.basketStore.info && this.props.basketStore.info.email) ||
				"",
			emailError: "",
			validateBasket: false,
			isSureModalVisible: false,
			mode: this.props.basketStore.mode,
			tableName: this.props.basketStore.tableName,
			selected: this.props.basketStore.mode || "",
		};
	}

	static navigationOptions = {
		headerShown: false,
	};

	chooseMode = (mode, tableName, comment) => {
		let action = {
			type: "CHANGE_MODE",
			values: {
				mode: mode,
				tableName: tableName,
				comment: comment,
			},
		};
		this.props.dispatch(action);
		this.setState({
			mode: mode,
			tableName: tableName,
			comment: comment,
		});
	};

	clearCart() {
		let action = {
			type: "CLEAR_BASKET",
		};
		this.props.dispatch(action);
		this.props.navigation.navigate("HomeScreen", {
			appId: this.props.appStore.app.appId,
		});
	}

	getMenusInfos() {
		let totalPrice = getTotalPrice(this.props.basketStore.items);
		let action = {
			type: "SAVE_BASKET_INFO",
			values: {
				totalPrice: totalPrice,
			},
		};
		this.props.dispatch(action);
	}

	componentDidMount() {
		this.getMenusInfos();
		this.validateEmail(this.state.email);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.basketStore.updatedAt != prevProps.basketStore.updatedAt) {
			this.getMenusInfos();
		}
	}

	validateEmail = (text) => {
		const error = {};
		console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
		// if( text.lenght === 0) {

		// }
		if (reg.test(text) === false) {
			console.log("Email is Not Correct");
			error.email = "Adresse email invalide";
			this.setState({
				email: text,
				validated: false,
				emailError: "Adresse email invalide",
			});
			return false;
		} else if (reg.test(text) === true) {
			this.setState({ email: text, validated: true, emailError: "" });
		}
	};

	render() {
		// console.log('tableName', this.state.tableName, this.props.basketStore.tableName)
		console.log("caisse", this.props.appStore.restaurant.hasCaisse);
		const colors = this.props.appStore.restaurant.style;
		const { navigate } = this.props.navigation;

		return (
			<View style={styles.container}>
				{this.state.isSureModalVisible && (
					<SureModal
						onBackdropPress={() => {
							this.setState({ isSureModalVisible: false });
						}}
						isModalVisible={this.state.isSureModalVisible}
						clearCart={() => {
							this.clearCart();
						}}
					/>
				)}
				<Banniere {...this.props.appStore} />
				{this.props.route.params && this.props.route.params.paymentFailed == 1 && (
					<View>
						<Tex style={styles.paymentError}>Le paiment a échoué</Tex>
					</View>
				)}
				<Animatable.View
					animation="pulse"
					easing="ease-out"
					iterationCount="infinite"
					style={{ width: "100%", alignItems: "center", marginVertical: 10 }}
				>
					<RedTex
						style={{
							backgroundColor: colors.secondary,
						}}
						numberOfLines={2}
						adjustsFontSizeToFit
						onPress={() => {
							this.props.navigation.navigate("RestaurantScreen", {
								appId: this.props.appStore.app.appId,
							});
						}}
					>
						Ajouter d'autres produits à ma commande
					</RedTex>
				</Animatable.View>
				<KeyboardAwareScrollView>
					<ScrollView showsVerticalScrollIndicator={false}>
						<Content>
							<HeaderRow
								onPress={() => {
									this.props.navigation.navigate("RestaurantScreen", {
										appId: this.props.appStore.app.appId,
									});
								}}
							>
								<MdReplyAll size={25} color={colors.primary} />
								<LSubTitle
									margin="5px 0 0 10px"
									style={{ color: colors.primary }}
								>
									Ma Commande
								</LSubTitle>
							</HeaderRow>

							<RowSetting>
								<BasketSettingItem
									bold
									title={"A EMPORTER"}
									icons="shopping"
									onpress={() => {
										this.chooseMode("takeAway", "", this.state.comment);
										// this.setState({ tableName: null })
									}}
									selected={this.state.mode == "takeAway"}
								/>

								<BasketSettingItem
									bold
									title={"SUR PLACE"}
									icon="restaurant"
									onpress={() => {
										this.chooseMode("onSite", this.state.tableName);
									}}
									selected={this.state.mode == "onSite"}
								/>
							</RowSetting>
							{this.state.mode == "onSite" &&
							this.props.appStore.restaurant.numberOfTables > 0 ? (
								<>
									<Tex color="#40403F" marg="10px 0 10px 0" fontSize="15">
										Indiquez votre numéro de table :
									</Tex>
									<StyledTextInput
										onChangeText={(tableName) => {
											this.chooseMode("onSite", tableName);
										}}
										multiline={false}
										keyboardType="numeric"
										maxLength={2}
										style={{
											width: "40%",
											height: 40,
											borderRadius: 10,
											textAlign: "center",
										}}
										placeholder={this.state.tableName || "N° de table"}
										value={this.state.tableName + ""}
									/>
								</>
							) : null}

							<Row style={{ backgroundColor: "#F7F7F7" }}>
								{this.props.basketStore.items.map((item, index) => {
									return (
										<BasketOrderItem
											onPress={(item) => {
												if (item.menuId) {
													navigate("MenuScreen", {
														menuId: item.menuId,
														timestamp: item.timestamp,
														restaurantName: this.props.appStore.restaurant.name,
														uri:
															"http://res.cloudinary.com/drive-eat/image/fetch/w_350/" +
															BASE_URL +
															this.props.appStore.restaurant.frontPicture,
														appId: this.props.appStore.app.appId,
														name: item.name,
													});
												}
											}}
											item={item}
											key={index}
										/>
									);
								})}
							</Row>
							<Row style={{ paddingTop: 10 }}>
								<StyledTextInput
									onChangeText={(comment) => {
										this.chooseMode(
											this.state.mode,
											this.state.tableName,
											comment
										);
									}}
									multiline={true}
									style={{ minHeight: 20 }}
									textAlignVertical="center"
									placeholder={
										this.state.comment || "Un commentaire sur la commande ?"
									}
									value={this.state.comment}
								/>
							</Row>
							<Row style={{ paddingTop: 10 }}>
								<PriceRow>
									<PriceText bold>TOTAL</PriceText>
									<PriceText bold>
										{" "}
										{getTotalPrice(
											this.props.basketStore.items,
											true,
											this.state.discountAmount
										)}{" "}
										€
									</PriceText>
								</PriceRow>
							</Row>
							<>
								<LTex fontSize="17px" marg="20px 0 10px 0">
									Informations suivi de commande :
								</LTex>
								<InputWrapper>
									<StyledInput
										name="name"
										onChangeText={(text) => {
											this.setState({ name: text });
										}}
										// onFocus={() => this.setState({ touched: true })}
										// onBlur={handleBlur('name')}
										value={this.state.name}
										returnKeyType={"next"}
										placeholder="Prénom - Nom"
										onSubmitEditing={() => {
											this.emailInput.focus();
										}}
										blurOnSubmit={false}
										required
										// border={this.state.touched ? "1px solid #f5c6cb" : "none"}
									/>
								</InputWrapper>
								<InputWrapper>
									<StyledInput
										keyboardType="email-address"
										name="email"
										onChangeText={(text) => {
											// this.setState({ emailError: text })
											this.validateEmail(text);
										}}
										// onFocus={() => this.setState({ touched: true })}
										// onEndEditing={(text) => this.setState({ emailError: text })}
										value={this.state.email}
										returnKeyType={"next"}
										placeholder="Adresse email"
										ref={(input) => {
											this.emailInput = input;
										}}
										onSubmitEditing={(text) => {
											// this.setState({ emailError: text });
											// this.submit.focus();
										}}
										// blurOnSubmit={false}
										required
										// border={this.state.touched ? "1px solid #f5c6cb" : "none"}
									/>
									<MandatoryText
										color={
											!this.state.validated && this.state.touched
												? "#721c24"
												: null
										}
									>
										Obligatoire
									</MandatoryText>
								</InputWrapper>
								{this.state.emailError ? (
									<LTex>{this.state.emailError}</LTex>
								) : null}
							</>
							<BottomRow>
								<StyledButton
									bgColor={"#818181"}
									width="40%"
									onPress={() => {
										this.setState({ isSureModalVisible: true });
									}}
								>
									<WhiteText fontSize="18px" bold>
										ANNULER
									</WhiteText>
								</StyledButton>
								<StyledButton
									ref={(input) => {
										this.submit = input;
									}}
									colors={colors}
									width="40%"
									disabled={!this.state.email || !this.state.validated}
									onPress={() => {
										let action = {
											type: "SAVE_INFO_ORDER",
											values: {
												info: {
													name: this.state.name,
													email: this.state.email,
												},
											},
										};
										this.props.dispatch(action);
										this.props.navigation.navigate("PaymentChoiceScreen", {
											appId: this.props.appStore.app.appId,
										});
									}}
								>
									<WhiteText fontSize="18px" bold>
										VALIDER
									</WhiteText>
								</StyledButton>
							</BottomRow>
						</Content>
					</ScrollView>
				</KeyboardAwareScrollView>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#f7f7f7",
	},
	paymentError: {
		color: "red",
		fontSize: 25,
	},
});

const mapStateToProps = (state) => {
	return state;
};

export default connect(mapStateToProps)(withRouter(BasketScreen));
