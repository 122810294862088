import { Persistor, Store } from "../store/configureStore";

// export default  {primary: (Store.getState().appStore.restaurant.style && Store.getState().appStore.restaurant.style.primary) || "#23253f",
// secondary: "#EA5B0C",
// border: "#DDD",
// wrapperHeight: "18%"}
const colors = {
	primary: "#23253f",
	secondary: "#EA5B0C",
	border: "#DDD",
	wrapperHeight: "18%",
	bg: "#000000",
};
//
// export default {...Store.getState().appStore.restaurant.style};
//export default Object.assign({...colors}, {...Store.getState().appStore.restaurant.style})
export default colors;

// ...Store.getState().appStore.restaurant.style,
// primary: "#23253f",
// secondary: "#EA5B0C",
// border: "#DDD",
// wrapperHeight: "18%"

// export default function getColors(){
//     return {...colors,
//         primary: "#000",
//         secondary: "#EE7523",
//         border: "#DDD",
//         wrapperHeight: "18%"
//     }

// }
