import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import Spinner from 'react-spinkit'

const { height, width } = Dimensions.get('window')

class Loader extends React.Component {
	render() {
		return (
			<View style={[styles.container]}>
				<Spinner size='large' color={this.props.color || props.colors.secondary} name={this.props.name} />
			</View>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
})

export default Loader
